import sendRequest from '../../../libs/sendRequest'

export const rentals = {
    namespaced: true,
    state: {
        rentals: [],
        rent: [],
        avaliable_drivers_vehicles: [],
        last_page: false,
        total_pages: null
    },
    getters: {
        getAvaliableDriversVehicles: (state) => (state.avaliable_drivers_vehicles),
        getRentals: (state) => (state.rentals),
        getRent: (state) => (state.rent),
        getLastPage: (state) => (state.last_page),
        getTotalPages: (state) => (state.total_pages)
    },
    mutations: {
        setRentals: (state, payload) => state.rentals = payload,
        setRent: (state, payload) => state.rent = payload,
        setAvaliableDriversVehicles: (state, payload) => state.avaliable_drivers_vehicles = payload,
        setLastPage: (state, payload) => state.last_page = payload,
        setTotalPages: (state, payload) => state.total_pages = payload
    },
    actions: {
        listRentals: async ({ commit, rootGetters}, payload) => {
            const custom_headers = rootGetters.getHeaders;

            let query = {
                datatable: payload.datatable,
                page: payload.page,
                rows: payload.rows,
                search: '',
                order_by: 'id_rental',
                order_asc: payload.order_asc,
                status: payload.status
            }

            if(payload.search) {
                query.search = payload.search
            }

            const response =  await sendRequest('rental/rentals/list', 'GET', null, null, query, custom_headers);

            if(response.success && response.data) {

                if(query.datatable) {
                    commit('setRentals', response.data.list.result ?? [])
                    commit('setTotalPages', response.data.list.total_pages)
                    if(response.data.list.total_pages == query.page) {
                        commit('setLastPage', true)
                    }
                    else {
                        commit('setLastPage', false)
                    }
                }

                else {
                    commit('setRentals', response.data.list ?? [])
                }
            }

            if(!response.success && response.errors) {
                for(let error in response.errors) {
                    console.log(response.errors[error])
                }
            }

            if(response.success && !response.data) {
                console.log('Se perdio la conexión con la API')
            }
        },
        viewRent: async ({commit, rootGetters}, payload) => {
            const custom_headers = rootGetters.getHeaders;

            let query = {
                id_rental: payload.id_rental
            }

            const response = await sendRequest('rental/rentals/view', 'GET', null, null, query, custom_headers)

            if(response.success && response.data) {
                commit('setRent', response.data.view)
            }

            if(!response.success && response.errors) {
                for(let error in response.errors) {
                    console.log(response.errors[error])
                }
            }

            if(!response.data && response.success) {
                console.log('se perdio la conexión con la API')
            }
        },
        addRent: async ({commit, rootGetters}, payload) => {
            const custom_headers = rootGetters.getHeaders;

            let body = {
                id_driver: payload.id_driver,
                id_vehicle: payload.id_vehicle,
                rent_discount: payload.rent_discount
            }

            const response = await sendRequest('rental/rentals/add', 'POST', null, body, null, custom_headers)

            if(response.success && response.data) {
                commit('setAlert', {
                    open: true,
                    message: 'Renta añadida con éxito',
                    text_color: 'success'
                  }, { root: true})
            }

            if(!response.success && response.errors) {
                for(let error in response.errors) {
                    console.log(response.errors[error])
                    if(response.errors[error].code == 'error_vehicle_has_already_been_rented') {
                        commit('setAlert', {
                            open: true,
                            message: 'Info: El vehiculo ya esta siendo rentado',
                            text_color: 'primary'
                          }, { root: true})
                    }
                    else if( response.errors[error].code ==  'error_driver_has_already_been_rented') {
                        commit('setAlert', {
                            open: true,
                            message: 'Info: El conductor ya esta rentado un vehiculo',
                            text_color: 'primary'
                          }, { root: true})
                    }
                    else if( response.errors[error].code == 'error_empty_document') {
                        commit('setAlert', {
                            open: true,
                            message: `Documento faltante: ${response.errors[error].detail.type_name}`,
                            text_color: 'warning'
                          }, { root: true})
                    }

                    else if( response.errors[error].code == 'error_expired_document') {
                        commit('setAlert', {
                            open: true,
                            message: `Documento expirado: ${response.errors[error].detail.type_name} fecha: ${response.errors[error].detail.expiration_Date}`,
                            text_color: 'warning'
                          }, { root: true})
                    }

                    else {
                        commit('setAlert', {
                            open: true,
                            message: 'Error: la operación no se ha completado correctamente',
                            text_color: 'danger'
                          }, { root: true})
                    }
                }
                
            }

            if(!response.data && response.success) {
                console.log('se perdio la conexión con la API')
            }
        },
        finishRent: async ({commit, rootGetters}, payload) => {
            const custom_headers = rootGetters.getHeaders;

            let query = {
                id_rental: payload.id_rental
            }

            const response = await sendRequest('rental/rentals/inactive', 'DELETE', null, null, query, custom_headers)

            if(response.success && response.data) {
                commit('setAlert', {
                    open: true,
                    message: 'Renta finalizada con éxito',
                    text_color: 'success'
                  }, { root: true})
            }

            if(!response.success && response.errors) {
                for(let error in response.errors) {
                    console.log(response.errors[error])
                }

                commit('setAlert', {
                    open: true,
                    message: 'Error: la operación no se ha completado correctamente',
                    text_color: 'danger'
                  }, { root: true})
            }

            if(!response.data && response.success) {
                console.log('se perdio la conexión con la API')
            }
        },
        avaliableDriversVehicles: async ({commit, rootGetters}, payload) => {
            const custom_headers = rootGetters.getHeaders

            const response = await sendRequest('rental/rentals/avaliable_drivers_vehicles', 'GET', null, null, null, custom_headers)

            if(response.success && response.data) {

                commit('setAvaliableDriversVehicles', response.data.list)
            }

            if(!response.success && response.errors) {
                for(let error in response.errors) {
                    console.log(response.errors[error])
                }
            }

            if(response.success && !response.data) {
                console.log('Se perdio la conexión con la API')
            }


        },
        editDiscount: async ({ commit, rootGetters}, payload) => {

            const custom_headers = rootGetters.getHeaders;

            const query = {
                id_rental: payload.id_rental
            }

            const body = {
                rent_discount: payload.rent_discount
            }

            const response = await sendRequest('rental/rentals/update', 'PUT', null, body, query, custom_headers);

            if(response.success && response.data) {
                commit('setAlert', {
                    open: true,
                    message: 'Descuento actualizado con éxito',
                    text_color: 'success'
                  }, { root: true})
            }

            if(!response.success && response.errors) {
                for(let error in response.errors) {
                    console.log(response.errors[error])
                }
                commit('setAlert', {
                    open: true,
                    message: 'Un error ha ocurrido intente más tarde',
                    text_color: 'danger'
                  }, { root: true})
            }

            if(!response.data && response.success) {
                console.log('se perdio la conexión con la API')
            }
        }
    }
}