<template>
    <div class="card-wrapper bg-dark-gray p-3">
        <div :class="`circle bg-${color}`">
            <span>
                {{ incidents}}
            </span>
            <span>
                Incidencias
            </span>
        </div>
        <p class="mt-2">
            {{ responsible }}
        </p>
        <p>
            Responsable
        </p>
        <button class="btn btn-outline-white"
            @click.stop="btnHandler"
            type="button">
            Ver incidencias
        </button>
    </div>
</template>

<script>
    export default {
        props: {
            color: {
                type: String,
                required: false,
                default: 'danger'
            },
            responsible: {
                type: String,
                required: false,
                default: 'Jaime Duende'
            },
            incidents: {
                type: [String, Number], 
                required: false,
                default: '14'
            }
        },  
        data() {
            return {

            }
        },
        emits: ['btn:handler'],
        methods: {
            btnHandler() {
                this.$emit('btn:handler')
            }
        }
    }
</script>

<style lang="scss" scoped>

    .card-wrapper {
        width: 250px;
        height: 250px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-radius: 25px;

        .circle {
            width: 80px;
            height: 80px;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            flex-direction: column;
            align-items: center;
            font-size: 12px;
        }
    }

</style>