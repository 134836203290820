import sendRequest from "../../../libs/sendRequest"

export const dashboard = {
    namespaced: true,
    state: {
        report_data: [],
        vehicle_detail: [],
        responsible_detail: [],
        last_page: false,
        total_pages: null
    },
    getters: {
        getReportData: (state) => (state.report_data),
        getVehicleDetail: (state) => (state.vehicle_detail),
        getResponsibleDetail: (state) => (state.responsible_detail),
        getLastPage: (state) => (state.last_page),
        getTotalPages: (state) => (state.total_pages)
    },
    mutations: {
        setReportData: (state, payload) => state.report_data = payload,
        setVehicleDetail: (state, payload) => state.vehicle_detail = payload,
        setResponsibleDetail: (state, payload) => state.responsible_detail = payload,
        setLastPage: (state, payload) => state.last_page = payload,
        setTotalPages: (state, payload) => state.total_pages = payload
    },
    actions: {
        list: async({commit, rootGetters}, query) => {
            const custom_headers = rootGetters.getHeaders
            
            const response = await sendRequest('reports/rentals/incident_dashboard','GET', null, null, query, custom_headers)

            if(response.success && response.data) {
                if(query.datatable) {
                    commit('setReportData', response.data.list.result)
                    commit('setTotalPages', response.data.list.total_pages)
                    if(response.data.list.total_pages == query.page) {
                        commit('setLastPage', true)
                    }
                    else {
                        commit('setLastPage', false)
                    }
                }

                else {
                    commit('setReportData', response.data.list)
                }
            }

            else if(!response.success && response.errors) {
                for(let i in response.errors) {
                    console.log(response.errors[i])
                }
            }

            else if(response.success && !response.data) {

            }

        },
        vehicleDetail: async({commit, rootGetters}, id) => {
            const custom_headers = rootGetters.getHeaders
            
            const response = await sendRequest('reports/rentals/incident_dashboard','GET', null, null, {datatable: false, id_vehicle: id}, custom_headers)

            if(response.success && response.data) {
                commit('setVehicleDetail', response.data.list ?? [])
            }   

            else if(!response.success && response.errors) {
                for(let i in response.errors) {
                    console.log(response.errors[i])
                }
            }

            else if(response.success && !response.data) {
                console.log('se perdio la conexión con la API.')
            }
        },
        responsibleDetail: async({commit, rootGetters}, id) => {
            const custom_headers = rootGetters.getHeaders
            
            const response = await sendRequest('reports/rentals/incident_dashboard','GET', null, null, {datatable: false, id_responsible: id}, custom_headers)

            if(response.success && response.data) {
                commit('setResponsibleDetail', response.data.list ?? [])
            }   

            else if(!response.success && response.errors) {
                for(let i in response.errors) {
                    console.log(response.errors[i])
                }
                commit('setResponsibleDetail', [])
            }

            else if(response.success && !response.data) {
                console.log('se perdio la conexión con la API.')
            }
        }
    }
}