import sendRequest from '../../../libs/sendRequest'

export const users = {
    namespaced: true,
    state: {
        user: [],
        users: [],
        last_page: false,
        total_pages: null
    },
    getters: {
        getUsers: (state) => (state.users),
        getUser: (state) => (state.user),
        getLastPage: (state) => (state.last_page),
        getTotalPages: (state) => (state.total_pages)
    },
    mutations: {
        setUser: (state, payload) => state.user = payload,
        setUsers: (state, payload) => state.users = payload,
        setLastPage: (state, payload) => state.last_page = payload,
        setTotalPages: (state, payload) => state.total_pages = payload
    },
    actions: {
        listUsers: async ({ commit, rootGetters}, payload) => {
            const custom_headers = rootGetters.getHeaders;

            let query = {
                datatable: payload.datatable ? payload.datatable : false,
                page: payload.page,
                rows: payload.rows,
                search: '',
                order_by: 'id_user',
                order_asc: payload.order_asc,
                status: payload.status,
                id_rol: payload.id_rol || null
            }
            
            if(payload.search) {
                query.search = payload.search
            }

            const response =  await sendRequest('core/users/list', 'GET', null, null, query, custom_headers);
            
            if(response.success && response.data) {
                commit('setUsers', response.data.list.result || response.data.list)
                commit('setTotalPages', response.data.list.total_pages)
                if(response.data.list.total_pages == query.page) {
                    commit('setLastPage', true)
                }
                else {
                    commit('setLastPage', false)
                }
            }

            if(!response.success && response.errors) {
                for(let error in response.errors) {
                    console.log(response.errors[error])
                }
            }

            if(response.success && !response.data) {
                console.log('Se perdio la conexión con la API')
            }
        },
        viewUser: async ({commit, rootGetters}, payload) => {
            const custom_headers = rootGetters.getHeaders;

            let query = {
                id_user: payload.id_user
            }

            const response = await sendRequest('core/users/view', 'GET', null, null, query, custom_headers)

            if(response.success && response.data) {
                commit('setUser', response.data.view)
            }

            if(!response.success && response.errors) {
                for(let error in response.errors) {
                    console.log(response.errors[error])
                }
            }

            if(!response.data && response.success) {
                console.log('se perdio la conexión con la API')
            }
        },
        addUser: async ({commit, rootGetters}, payload) => {
            const custom_headers = rootGetters.getHeaders;

            let body = {
                username: payload.username,
                id_rol: Number(payload.id_rol),
                phone: payload.phone,
                password: payload.password,
                first_name: payload.first_name,
                last_name: payload.last_name,
                second_surname: payload.second_surname
            }

            const response = await sendRequest('core/users/add', 'POST', null, body, null, custom_headers)

            if(response.success && response.data) {
                commit('setAlert', {
                    open: true,
                    message: 'Usuario añadido con éxito',
                    text_color: 'success'
                  }, { root: true})
            }

            if(!response.success && response.errors) {
                for(let error in response.errors) {
                    console.log(response.errors[error])
                }

                commit('setAlert', {
                    open: true,
                    message: 'Error: la operación no se ha completado correctamente',
                    text_color: 'danger'
                  }, { root: true})
            }

            if(!response.data && response.success) {
                console.log('se perdio la conexión con la API')
            }
        },
        updateUser: async ({commit, rootGetters}, payload) => {
            const custom_headers = rootGetters.getHeaders;

            let query = {
                id_user: payload.id_user
            }

            let body = {
                username: payload.username,
                id_rol: payload.id_rol,
                first_name: payload.first_name,
                last_name: payload.last_name,
                second_surname: payload.second_surname,
                phone: payload.phone
            }

            const response = await sendRequest('core/users/update', 'PUT', null, body, query, custom_headers)

            if(response.success && response.data) {
                commit('setAlert', {
                    open: true,
                    message: 'Usuario actualizada con éxito',
                    text_color: 'success'
                }, { root: true})
            }

            if(!response.success && response.errors) {
                for(let error in response.errors) {
                    console.log(response.errors[error])
                }
                commit('setAlert', {
                    open: true,
                    message: 'Error: la operación no se ha completado correctamente',
                    text_color: 'danger'
                }, { root: true})
            }

            if(!response.data && response.success) {
                console.log('se perdio la conexión con la API')
            }
        },
        deleteUser: async ({commit, rootGetters}, payload) => {
            const custom_headers = rootGetters.getHeaders;

            let query = {
                id_user: payload.id_user
            }

            const response = await sendRequest('core/users/inactive', 'DELETE', null, null, query, custom_headers)

            if(response.success && response.data) {
                commit('setAlert', {
                    open: true,
                    message: 'Usuario eliminada con éxito',
                    text_color: 'success'
                }, { root: true})
            }

            if(!response.success && response.errors) {
                for(let error in response.errors) {
                    console.log(response.errors[error])
                }

                commit('setAlert', {
                    open: true,
                    message: 'Error: la operación no se ha completado correctamente',
                    text_color: 'danger'
                }, { root: true})
            }

            if(!response.data && response.success) {
                console.log('se perdio la conexión con la API')
            }
        },
        changePassword: async ({ commit, rootGetters}, payload) =>{
            const custom_headers = rootGetters.getHeaders;

            const query = {
                id_user: payload.id_user
            }

            const body = {
                password: payload.password
            }

            const response = await sendRequest('core/users/change_password', 'PUT', null, body, query, custom_headers);

            if(response.success && response.data) {
                commit('setAlert', {
                    open: true,
                    message: 'La contraseña se ha actualizado correctamente',
                    text_color: 'success'
                }, { root: true})
            }

            if(!response.success && response.errors) {
                for(let error in response.errors) {
                    console.log(resonse.errors[error])
                }

                commit('setAlert', {
                    open: true,
                    message: 'Error: Un error ha ocurrido intente más tarde',
                    text_color: 'danger'
                }, { root: true})
            }

            if(response.success && !response.data) {
                console.log('Se ha perdido una conexión con la API.')
            }
        }
    }
}