import sendRequest from '../../../libs/sendRequest'

export const drivers = {
    namespaced: true,
    state: {
        drivers: [],
        driver: [],
        report_url: null,
        last_page: false,
        total_pages: null
    },
    getters: {
        getDrivers: (state) => (state.drivers),
        getDriver: (state) => (state.driver),
        getLastPage: (state) => (state.last_page),
        getReporUrl: (state) => (state.report_url),
        getTotalPages: (state) => (state.total_pages),
        getReportUrl: (state) => (state.report_url)
    },
    mutations: {
        setDrivers: (state, payload) => state.drivers = payload,
        setDriver: (state, payload) => state.driver = payload,
        setLastPage: (state, payload) => state.last_page = payload,
        setTotalPages: (state, payload) => state.total_pages = payload,
        setReportUrl: (state, payload) => state.report_url = payload
    },
    actions: {
        listDrivers: async ({ commit, rootGetters}, payload) => {
            const custom_headers = rootGetters.getHeaders;

            let query = {
                datatable: payload.datatable,
                page: payload.page,
                rows: payload.rows,
                search: '',
                order_by: 'id_driver',
                order_asc: payload.order_asc,
                status: payload.status
            }

            if(payload.search) {
                query.search = payload.search
            }

            const response =  await sendRequest('rental/drivers/list', 'GET', null, null, query, custom_headers);

            if(response.success && response.data) {

                if(query.datatable) {
                    commit('setDrivers', response.data.list.result ?? [])
                    commit('setTotalPages', response.data.list.total_pages)
                    if(response.data.list.total_pages == query.page) {
                        commit('setLastPage', true)
                    }
                    else {
                        commit('setLastPage', false)
                    }
                }

                else {
                    commit('setDrivers', response.data.list ?? [])
                }
            }

            if(!response.success && response.errors) {
                for(let error in response.errors) {
                    console.log(response.errors[error])
                }
            }

            if(response.success && !response.data) {
                console.log('Se perdio la conexión con la API')
            }
        },
        viewDriver: async ({commit, rootGetters}, payload) => {
            const custom_headers = rootGetters.getHeaders;

            let query = {
                id_driver: payload.id_driver
            }

            const response = await sendRequest('rental/drivers/view', 'GET', null, null, query, custom_headers)

            if(response.success && response.data) {
                commit('setDriver', response.data.view)
            }

            if(!response.success && response.errors) {
                for(let error in response.errors) {
                    console.log(response.errors[error])
                }
            }

            if(!response.data && response.success) {
                console.log('se perdio la conexión con la API')
            }
        },
        addDriver: async ({commit, rootGetters}, payload) => {
            const custom_headers = rootGetters.getHeaders;

            let body = {
                first_name: payload.first_name,
                last_name: payload.last_name,
                second_surname: payload.second_surname,
                gender: payload.gender,
                email: payload.email,
                phone: payload.phone,
                picture: payload.picture,
                incident_fund: payload.incident_fund
            }

            const response = await sendRequest('rental/drivers/add', 'POST', null, body, null, custom_headers)

            if(response.success && response.data) {
                commit('setAlert', {
                    open: true,
                    message: 'Conductor añadido con éxito',
                    text_color: 'success'
                  }, { root: true})
            }

            if(!response.success && response.errors) {

                let message = ''

                for(let error in response.errors) {
                    switch(response.errors[error].code) {
                        case 'error_incident_fund_may_not_be_less_than_1000':
                            message = 'La fianza no puede ser menor de 1000'
                            break;
                        case 'error_driver_phone_already_exists':
                            message = 'Error: El telefono ya esta registrado.';
                            break;
                        default: 
                            message = 'Error: la operación no se ha completado correctamente.'
                            break;
                    }
                }

                commit('setAlert', {
                    open: true,
                    message: message,
                    text_color: 'danger'
                  }, { root: true})
            }

            if(!response.data && response.success) {
                console.log('se perdio la conexión con la API')
            }
        },
        updateDriver: async ({commit, rootGetters}, payload) => {
            const custom_headers = rootGetters.getHeaders;

            let query = {
                id_driver: payload.id_driver
            }

            let body = {
                first_name: payload.first_name,
                last_name: payload.last_name,
                second_surname: payload.second_surname,
                gender: payload.gender,
                email: payload.email,
                phone: payload.phone
            }

            const response = await sendRequest('rental/drivers/update', 'PUT', null, body, query, custom_headers)

            if(response.success && response.data) {
                commit('setAlert', {
                    open: true,
                    message: 'Conductor actualizado con éxito',
                    text_color: 'success'
                  }, { root: true})
            }

            if(!response.success && response.errors) {
                for(let error in response.errors) {
                    console.log(response.errors[error])
                }

                commit('setAlert', {
                    open: true,
                    message: 'Error: la operación no se ha completado correctamente',
                    text_color: 'danger'
                  }, { root: true})
            }

            if(!response.data && response.success) {
                console.log('se perdio la conexión con la API')
            }
        },
        deleteDriver: async ({commit, rootGetters}, payload) => {
            const custom_headers = rootGetters.getHeaders;

            let query = {
                id_driver: payload.id_driver
            }

            const response = await sendRequest('rental/drivers/inactive', 'DELETE', null, null, query, custom_headers)

            if(response.success && response.data) {
                commit('setAlert', {
                    open: true,
                    message: 'Conductor eliminado con éxito',
                    text_color: 'success'
                  }, { root: true})
            }

            if(!response.success && response.errors) {
                for(let error in response.errors) {
                    console.log(response.errors[error])
                }

                commit('setAlert', {
                    open: true,
                    message: 'Error: la operación no se ha completado correctamente',
                    text_color: 'danger'
                  }, { root: true})
            }

            if(!response.data && response.success) {
                console.log('se perdio la conexión con la API')
            }
        },
        adressUpdate: async({commit, rootGetters}, payload) => {
            const custom_headers = rootGetters.getHeaders;

            const query = {
                id_driver: payload.id_driver,
                movement: payload.movement,
            }

            if(payload.movement == 'change') {
                query.id_driver_address = payload.id_driver_address
            }

            const body = {
                city: payload.city,
                street: payload.street,
                postal_code: payload.postal_code,
                id_driver: payload.selected_id,
                street: payload.street,
                picture: payload.picture,
                colony: payload.colony,
                id_region: payload.id_region,
                outer_number: payload.outside_number,
                inside_number: payload.inside_number,
                municipality: payload.municipality
            }

            const response = await sendRequest('rental/drivers/change_address', 'POST', null, body, query, custom_headers)

            if(response.success && response.data) {
                commit('setAlert', {
                    open: true,
                    message: 'Dirección añadida con éxito',
                    text_color: 'success'
                  }, { root: true})
            }

            if(!response.success && response.errors) {
                for(let error in response.errors) {
                    console.log(response.errors[error])
                }

                commit('setAlert', {
                    open: true,
                    message: 'Error: la operación no se ha completado correctamente',
                    text_color: 'danger'
                  }, { root: true})
            }

            if(!response.data && response.success) {
                console.log('se perdio la conexión con la API')
            }

        },
        sendDocument: async({commit, rootGetters }, payload) => {
            const custom_headers = rootGetters.getHeaders;

            const response = await sendRequest('rental/drivers/add_documents','POST', null, payload.body, payload.query, custom_headers);

            if(response.success && response.data) {
                commit('setAlert', {
                    open: true,
                    message: 'Documento añadido con éxito',
                    text_color: 'success'
                  }, { root: true})
            }

            if(!response.success && response.errors) {
                for(let error in response.errors) {
                    console.log(response.errors[error])
                }

                commit('setAlert', {
                    open: true,
                    message: 'Error: la operación no se ha completado correctamente',
                    text_color: 'danger'
                  }, { root: true})
            }

            if(!response.data && response.success) {
                console.log('se perdio la conexión con la API')
            }
        },
        report: async({ commit, rootGetters}, payload) => {
            const custom_headers = rootGetters.getHeaders;

            let query = {
                id_driver: payload.id_driver
            }

            const response = await sendRequest('reports/rentals/driver', 'GET', null, null, query, custom_headers)

            if(response.success && response.data) {
                commit('setReportUrl', response.data.view.url)
            }

            if(!response.success && response.errors) {
                for(let error in response.errors) {
                    console.log(response.errors[error])
                }
            }

            if(!response.data && response.success) {
                console.log('se perdio la conexión con la API')
            }
        }
    }
}