import sendRequest from '../../../libs/sendRequest'

export const unit_administration = {
    namespaced: true,
    state: {
        units: [],
        unit: [],
        models: [],
        plates: null,
        report_url: null,
        last_page: false,
        total_pages: null
    },
    getters: {
        getUnits: (state) => (state.units),
        getUnit: (state) => (state.unit),
        getPlates: (state) => (state.plates),
        getLastPage: (state) => (state.last_page),
        getTotalPages: (state) => (state.total_pages),
        getModels: (state) => (state.models),
        getReportUrl: (state) => (state.report_url)
    },
    mutations: {
        setUnits: (state, payload) => state.units = payload,
        setUnit: (state, payload) => state.unit = payload,
        setPlates: (state, payload) => state.plates = payload,
        setLastPage: (state, payload) => state.last_page = payload,
        setTotalPages: (state, payload) => state.total_pages = payload,
        setModels: (state, payload) => state.models = payload,
        setReportUrl: (state, payload) => state.report_url = payload
    },
    actions: {
        listVehicles: async ({ commit, rootGetters}, payload) => {
            const custom_headers = rootGetters.getHeaders;

            let query = {
                datatable: payload.datatable,
                page: payload.page,
                rows: payload.rows,
                search: '',
                order_by: 'id_vehicle',
                order_asc: payload.order_asc,
                status: payload.status
            }

            if(payload.search) {
                query.search = payload.search
            }

            const response =  await sendRequest('rental/vehicles/list', 'GET', null, null, query, custom_headers);

            if(response.success && response.data) {
                if(query.datatable) {
                    commit('setUnits', response.data.list.result ?? [])
                    commit('setTotalPages', response.data.list.total_pages)
                    if(response.data.list.total_pages == query.page) {
                        commit('setLastPage', true)
                    }
                    else {
                        commit('setLastPage', false)
                    }
                }

                else {
                    commit('setUnits', response.data.list ?? [])
                }
            }

            if(!response.success && response.errors) {
                for(let error in response.errors) {
                    console.log(response.errors[error])
                }
            }

            if(response.success && !response.data) {
                console.log('Se perdio la conexión con la API')
            }
        },
        viewVehicle: async ({commit, rootGetters}, payload) => {
            const custom_headers = rootGetters.getHeaders;

            let query = {
                id_vehicle: payload.id_vehicle
            }

            const response = await sendRequest('rental/vehicles/view', 'GET', null, null, query, custom_headers)

            if(response.success && response.data) {
                commit('setUnit', response.data.view)
            }

            if(!response.success && response.errors) {
                for(let error in response.errors) {
                    console.log(response.errors[error])
                }
            }

            if(!response.data && response.success) {
                console.log('se perdio la conexión con la API')
            }
        },
        addVehicle: async ({commit, rootGetters}, payload) => {
            const custom_headers = rootGetters.getHeaders;

            let body = {
                id_model: payload.id_model,
                id_color: payload.id_color,
                serial_number: payload.serial_number,
                year: payload.year,
                rental_rate: Number(payload.rental_rate).toFixed(2),
                acquisition_cost: Number(payload.acquisition_cost).toFixed(2),
                current_mileage: payload.current_mileage,
                hologram: payload.hologram,
                id_owner: payload.id_owner,
                motor_number: payload.motor_number,
                last_verification: payload.last_verification,
                plate: payload.plate,
                id_brand: payload.id_brand,
                id_region: payload.id_region,
                picture: payload.picture
            }

            const response = await sendRequest('rental/vehicles/add', 'POST', null, body, null, custom_headers)

            if(response.success && response.data) {
                commit('setAlert', {
                    open: true,
                    message: 'Vehiculo añadido con éxito',
                    text_color: 'success'
                  }, { root: true})
            }

            if(!response.success && response.errors) {
                for(let error in response.errors) {
                    console.log(response.errors[error])
                }

                commit('setAlert', {
                    open: true,
                    message: 'Error: la operación no se ha completado correctamente',
                    text_color: 'danger'
                  }, { root: true})
            }

            if(!response.data && response.success) {
                console.log('se perdio la conexión con la API')
            }
        },
        updateVehicle: async ({commit, rootGetters}, payload) => {
            const custom_headers = rootGetters.getHeaders;

            let query = {
                id_vehicle: payload.id_vehicle
            }

            let body = {
                id_model: payload.id_model,
                id_color: payload.id_color,
                serial_number: payload.serial_number,
                year: payload.year,
                rental_rate: payload.rental_rate,
                acquisition_cost: payload.acquisition_cost,
                current_mileage: payload.current_mileage,
                hologram: payload.hologram,
                id_owner: payload.id_owner,
                motor_number: payload.motor_number
            }

            const response = await sendRequest('rental/vehicles/update', 'PUT', null, body, query, custom_headers)

            if(response.success && response.data) {
                commit('setAlert', {
                    open: true,
                    message: 'Vehiculo editado con éxito',
                    text_color: 'success'
                  }, { root: true})
            }

            if(!response.success && response.errors) {
                for(let error in response.errors) {
                    console.log(response.errors[error])
                }

                commit('setAlert', {
                    open: true,
                    message: 'Error: la operación no se ha completado correctamente',
                    text_color: 'danger'
                  }, { root: true})
            }

            if(!response.data && response.success) {
                console.log('se perdio la conexión con la API')
            }
        },
        deleteVehicle: async ({commit, rootGetters}, payload) => {
            const custom_headers = rootGetters.getHeaders;

            let query = {
                id_vehicle: payload.id_vehicle
            }

            const response = await sendRequest('rental/vehicles/inactive', 'DELETE', null, null, query, custom_headers)

            if(response.success && response.data) {
                commit('setAlert', {
                    open: true,
                    message: 'Conductor eliminado con éxito',
                    text_color: 'success'
                  }, { root: true})
            }

            if(!response.success && response.errors) {
                for(let error in response.errors) {
                    console.log(response.errors[error])
                }

                commit('setAlert', {
                    open: true,
                    message: 'Error: la operación no se ha completado correctamente',
                    text_color: 'danger'
                  }, { root: true})
            }

            if(!response.data && response.success) {
                console.log('se perdio la conexión con la API')
            }
        },
        addPlates: async ({commit, rootGetters}, payload) => {
            const custom_headers = rootGetters.getHeaders;

            let body = {
                plate: payload.plates,
                id_vehicle: payload.id_vehicle,
                id_region: payload.id_region
            }


            const response = await sendRequest('rental/vehicles/plates/add', 'POST', null, body, null, custom_headers)

            if(response.success && response.data) {
                commit('setAlert', {
                    open: true,
                    message: 'Placas añadidas con éxito',
                    text_color: 'success'
                  }, { root: true})
            }

            if(!response.success && response.errors) {
                for(let error in response.errors) {
                    console.log(response.errors[error])
                }

                commit('setAlert', {
                    open: true,
                    message: 'Error: la operación no se ha completado correctamente',
                    text_color: 'danger'
                  }, { root: true})
            }

            if(!response.data && response.success) {
                console.log('se perdio la conexión con la API')
            }


        },
        searchByBrand: async ({ commit, rootGetters}, payload) => {

            const custom_headers = rootGetters.getHeaders;

            const query = {
                id_brand: payload.id_brand
            }

            const response = await sendRequest('catalog/models/search_for_brand', 'GET', null, null, query, custom_headers);

            if(response.success && response.data) {
                
                let raw_data = response.data.view ?? [];

                let  options = raw_data.map((model) => {
                    return {
                        id: model.id_model,
                        label: model.model_name
                    }
                });

                options = [
                    {id: 0, label: 'Seleccione'},
                    ...options
                ]

                commit('setModels', options);
            }
        },
        report: async({ commit, rootGetters}, payload) => {
            const custom_headers = rootGetters.getHeaders;

            let query = {
                id_vehicle: payload.id_vehicle
            }

            const response = await sendRequest('reports/rentals/vehicle', 'GET', null, null, query, custom_headers)

            if(response.success && response.data) {
                commit('setReportUrl', response.data.view.url)
            }

            if(!response.success && response.errors) {
                for(let error in response.errors) {
                    console.log(response.errors[error])
                }
            }

            if(!response.data && response.success) {
                console.log('se perdio la conexión con la API')
            }
        }
    }
}