<template>
    <form class="input_container" :autocomplete="(!autocomplete) ? 'off' : 'on'">
        <div class="mb-1" v-if="label !== ''">
            <span :class="(theme) ? 'text-white' : 'text-black'">
                {{ label }}
            </span>
        </div>

        <input 
            :id="_id" 
            :value="value" 
            v-bind="$attrs" 
            v-on="listeners"
            :placeholder="placeholder" 
            :type="local_type" 
            :name="name" 
            :max="max"
            :min="min"
            :maxlength="max_length"
            :class="[
                (line) ? `input-line-thm-${theme} input-line-${variant}` : `input-thm-${theme} input-${variant}`, 
                (full) ? 'full-width' : '',
                (border_color !== null) ? `border-${border_color}` : '',
                (rounded) ? 'btn-pill': ''
            ]">

        <img :src="(local_type == 'password') ? icon_eye : icon_eye_slash" 
            @click="showPassword()" 
            class="icon_eye"
            :style="`${(local_type == 'password') ? 'margin-left: -30px; width: 18px;' : 'margin-left: -31px; width: 20px;' }`"
            v-if="type == 'password'">
    </form>
</template>

<script>
    // Images
    import icon_eye from 'ASSETS/icons/eye-icon'
    import icon_eye_slash from 'ASSETS/icons/eye-slash-icon'

    export default {
        name: 'ad-input',
        data() {
            return {
                icon_eye,
                icon_eye_slash,
                local_type: null
            }
        },
        props: {
            _id: {
                type: String,
                description: "id of html input",
                required: false,
                default: 'input-1'
            },
            value: {
                type: [String, Number, Date],
                description: "input value",
                required: false,
                default: () => {
                    return null
                }
            },
            type: {
                type: String,
                description: "type of input",
                required: false,
                default: 'text'
            },
            name: {
                type: String,
                description: "name of the input",
                required: false,
                default: ''
            },
            label: {
                type: String,
                description: "label for the input",
                required: false,
                default: ''
            },
            placeholder: {
                type: String,
                description: "placeholder of input",
                required: false,
                default: ''
            },
            line: {
                type: Boolean,
                description: "defines whether the inputs will be line or normal style",
                required: false,
                default: false
            },
            theme: {
                type: String,
                description: "defines whether the inputs will be of light or dark thema",
                required: false,
                default: "dark",
                validator: function (value) {
                    let allow_colors = ['dark', 'light']
                    return allow_colors.includes(value)
                }
            },
            variant: {
                type: String,
                description: "defines the focus color of the inputs",
                required: false,
                default: 'primary',
                validator: function (value) {
                    let allow_colors = CONFIG.class_colors
                    return allow_colors.includes(value)
                }
            },
            max_length: {
                type: Number,
                description: "max length inputs",
                required: false,
                default: null
            },
            full: {
                type: Boolean,
                description: 'defines whether the inputs will be as wide as possible',
                required: false,
                default: false
            },
            border_color: {
                type: String,
                description: 'defines the color of the input border',
                required: false,
                default: null
            },
            rounded: {
                type: Boolean,
                description: '',
                required: false,
                default: false
            },
            autocomplete: {
                type: Boolean,
                description: 'defines if input allow autocomplete',
                required: false,
                default: false
            },
            max: {
                type: String,
                required: false,
                default: ''
            },
            min: {
                type: String,
                required: false,
                default: ''
            }
        },
        model: {
            prop: 'value',
            event: 'input'
        },
        computed: {
            listeners() {
                return {
                    ...this.$listeners,
                    input: this.customInput,
                    focus: this.customFocus,
                    blur: this.customBlur,
                    change: this.customChangeValue
                }
            }
        },
        methods: {
            showPassword() {
                this.local_type = (this.local_type === 'password') ? 'text' : 'password'
            },
            // Este methodo se dispara en la ejecución del @INPUT del tag input original
            customInput(event) {
                //console.log('Origin INPUT',evt.target.value)
                this.$emit('input', event.target.value)
            },
            // Este methodo se dispara en la ejecución del @FOCUS del tag input original
            customFocus(event) {
                //console.log('Origin FOCUS',evt.target.value)
                this.$emit('focus', event.target.value)
            },
            // Este methodo se dispara en la ejecución del @BLUR del tag input original
            customBlur(event) {
                //console.log('Origin BLUR', evt.target.value)
                this.$emit('blur', event.target.value)
            },
            // Este methodo se dispara en la ejecución del @CHANGE del tag input original
            customChangeValue(event) {
                //console.log('Origin CHANGE', evt.target.value)
                this.$emit('change', event.target.value)
            }
        },
        created() {
            this.local_type = this.type
        }
    }
</script>

<style lang="scss">
    @import '../assets/scss/colors.scss';

    .input_container {

        .icon_eye {
            cursor: pointer;
        }
    }

    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
        transition: background-color 9999s ease-in-out 0s;
        -webkit-text-fill-color: white;
        caret-color: white;
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    input[type=number] { 
        -moz-appearance: textfield;
        appearance: textfield;
        margin: 0; 
    }
</style>