import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store/index'
Vue.use(VueRouter)

// Modules
import Login from 'ROUTER/modules/login.js'
import Dashboard from 'ROUTER/modules/dashboard.js'
import Units from 'ROUTER/modules/units.js'
import Drivers from 'ROUTER/modules/drivers.js'
import Incidents from 'ROUTER/modules/incidents.js'
import Accounting from 'ROUTER/modules/accounting.js'
import Catalogues from 'ROUTER/modules/catalogues.js'
import UserProfile from 'ROUTER/modules/userProfile.js'
import DriverProfile from 'ROUTER/modules/driverProfile.js'
import CarProfile from 'ROUTER/modules/carProfile.js'
import Rentals from 'ROUTER/modules/rentals.js'
import PagePrices from 'ROUTER/modules/pagePrices'
import Inventory from 'ROUTER/modules/inventory.js';

const routes = [
    ...Login,
    ...Dashboard,
    ...Units,
    ...Drivers,
    ...Rentals,
    ...Accounting,
    ...Incidents,
    ...Catalogues,
    ...UserProfile,
    ...DriverProfile,
    ...CarProfile,
    ...PagePrices,
    ...Inventory
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})



const allowed_without_session = ['login']
const allowed_with_session = ['dashboard','units','drivers','rentals','accounting','incidents','catalogues','user-profile', 'car-profile', 'driver-profile']

//roles 1 = administrador, 2 = supervisor, 3 = responsable

router.beforeEach((to, from, next) => {
    const session = store.getters['getSession']
    
    if((!allowed_without_session.includes(to.name) && session == null) || to.name == null) {
        next({
            name: 'login'
        })
    }

    else if(!session && to.name == 'login') {
        next()
    }

    else if(allowed_with_session.includes(from.name)  && to.name === 'login') {
       
        next(false);
    }

    else if((!from.name && to.name == 'login') && session !== null) {
        if(session.id_rol == 1) {
            next({
                name: 'dashboard'
            })
        }

        else {
            next({
                name: 'units'
            })
        }
    }

    else if(session && to.name == null) {
        if(session.id_rol == 1) {
            next({
                name: 'dashboard'
            })
        }
        else {
            next({
                name: 'units'
            })
        }
    }

    else if((to.name == 'driver-profile' || to.name == 'user-profile' || to.name == 'car-profile') && typeof(to.params.id) !== 'number') {
        next({
            name: 'dashboard'
        })
    }

    else if((to.name == 'catalogues' || to.name == 'dashboard' || to.name == 'accounting' || to.name == 'drivers') && session.id_rol !== 1) {
        
        next({
            name: 'units'
        })
    }

    
 
    else {
        next()
    }

    
  })
  

export default router
