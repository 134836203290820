<template>
    <div 
        id="alert_container" 
        :class="`alert ${data_alert.variant} all_transition_slow position-fixed border-${data_alert.text_color}`" 
        v-show="data_alert.open">

        <div class="d-flex justify-content-between align-items-center">
            <div class="pr-4">
                <span :class="`ad--description-sm text-${data_alert.text_color}`">
                    {{ data_alert.message }}
                </span>
            </div>
            <div>
                <span @click.stop="data_alert.open = false"
                :class="`closebtn all_transition text-${data_alert.text_color}`">
                    &times;
                </span>
            </div>
        </div>
    </div>
</template>

<script>
    import _Store from '@/store'
    import { mapGetters } from 'vuex'

    export default {
        name: 'ad-alert',
        computed: {
            ...mapGetters([
                'getAlert'
            ]),

            data_alert() {
                return this.getAlert
            }
        },
        data() {
            return {

            }
        },
        methods: {

        },
        
    }
</script>

<style lang="scss" scoped>
    @import '../assets/scss/colors.scss';

    #alert_container {
        background-color: $secondary;
        padding: 15px;
        min-width: 320px;
        top: 10px;
        right: 10px;
        opacity: 1;
        z-index: 10;

        .closebtn {
            opacity: .5;
            font-size: 28px;
            line-height: 28px;
            font-weight: bold;
            cursor: pointer;
            float: right;
        }

        .closebtn:hover {
            opacity: 1;
        }
    }

    #alert_container.primary { background-color: $primary; }
    #alert_container.secondary { background-color: $secondary; }
    #alert_container.success { background-color: $success; }
    #alert_container.warning { background-color: $warning; }
    #alert_container.danger  { background-color: $danger; }
    #alert_container.info { background-color: $info; }
    #alert_container.light { background-color: $light; }
    #alert_container.dark { background-color: $dark; }
</style>