import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

global.CONFIG = require('./page.config.js')

import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'

// Import Bootstrap an BootstrapVue CSS files (order is important)
import './assets/scss/custom-bootstrap.scss'

// Make BSidebar available throughout your project
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)


// Lodash
import _ from 'lodash'
global._ = _

//Request
import _Request from './libs/sendRequest.js'
Vue.prototype.$sendRequest = _Request


// Global components
import { Alert, Input, Checkbox, GroupCheckbox, Tab, Chip, Table, Chart, Spinner, Modal, DropFile, Select, IncidenceForm, IncidentCard, DriverCard, VehicleCard } from './components/index.js'


Vue.component('li-alert', Alert)
Vue.component('li-input', Input)
Vue.component('li-checkbox', Checkbox)
Vue.component('li-group-checkbox', GroupCheckbox)
Vue.component('li-tab', Tab)
Vue.component('li-chip', Chip)
Vue.component('li-table', Table)
Vue.component('li-chart', Chart)
Vue.component('li-spinner', Spinner)
Vue.component('li-modal', Modal)
Vue.component('li-select', Select )
Vue.component('li-dropfile', DropFile)
Vue.component('li-incidence-form', IncidenceForm)
Vue.component('li-incident-card', IncidentCard)
Vue.component('li-driver-card', DriverCard)
Vue.component('li-vehicle-card', VehicleCard)


//TEMPLATES
import  DEFAULT  from './templates/default'
Vue.component('default', DEFAULT)

import DASHBOARD from './templates/dashboard'
Vue.component('dashboard', DASHBOARD)

import SESSION from './templates/session'
Vue.component('session', SESSION)



Vue.config.productionTip = false

Vue.mixin({
    computed: {
        currentDate () {
            const raw_date = new Date().toISOString();
            const date = raw_date.slice(0,10)
            return date
        }
    },
    methods: {
        formatAmount(value, decimals = 2) {
            return Number(Math.round(value + 'e' + decimals) + 'e-' + decimals).toFixed(decimals)
        },
        dateParser(raw_date) {
            const date = new Date(raw_date).toLocaleDateString().replaceAll('/','-');
            let date_array = date.split('-');
            date_array = date_array.reverse();

            if(date_array[1].length < 2) {
                date_array[1] = `0${date_array[1]}`
            }

            if(date_array[2].length < 2) {
                date_array[2] = `0${date_array[2]}`
            }

            const final_date = date_array.join('-');
            return final_date;
        }
    }
})

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
