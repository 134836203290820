<template>
    <div id="template-session" class="p-0 m-0 container-fluid">

    	<sidebar bg_color="secondary-dark"></sidebar>

    	<section class="section_main">
    		<slot></slot>	
    	</section>

    	<section class="bg-color bg-dark">
    		
    	</section>
		<li-alert />
	</div>
</template>

<script>
	import Sidebar from '../components/Sidebar.vue'

	export default {
		name: 'template-session',
		components: {
            'sidebar': Sidebar,
        },
		data() {
			return {
                
			}
		},
	}
</script>


<style lang="scss" scoped>
    #template-session {
        //padding: 0px;

        .section_main {
        	min-height: 100vh;
	        padding: 0px 0px 0px 140px;
	        overflow-y: hidden !important;
        }


        .bg-color {
        	z-index: -1;
        	top: 0px;
        	left: 0px;
        	position: fixed;
        	height: 100vh;
        	width: 100vw;
        }
    }
</style>