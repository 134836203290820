import sendRequest from '../../../libs/sendRequest'

export const owners = {
    namespaced: true,
    state: {
        owner: [],
        owners: [],
        last_page: false,
        total_pages: null
    },
    getters: {
        getOwners: (state) => (state.owners),
        getOwner: (state) => (state.owner),
        getLastPage: (state) => (state.last_page),
        getTotalPages: (state) => (state.total_pages)
    },
    mutations: {
        setOwner: (state, payload) => state.owner = payload,
        setOwners: (state, payload) => state.owners = payload,
        setLastPage: (state, payload) => state.last_page = payload,
        setTotalPages: (state, payload) => state.total_pages = payload
    },
    actions: {
        listOwners: async ({ commit, rootGetters}, payload) => {
            const custom_headers = rootGetters.getHeaders;

            let query = {
                datatable: payload.datatable,
                page: payload.page,
                rows: payload.rows,
                search: '',
                order_by: 'id_owner',
                order_asc: payload.order_asc,
                status: payload.status
            }

            if(payload.search) {
                query.search = payload.search
            }

            const response =  await sendRequest('catalog/owners/list', 'GET', null, null, query, custom_headers);

            if(response.success && response.data) {
                if(query.datatable) {
                    commit('setOwners', response.data.list.result ?? [])
                    commit('setTotalPages', response.data.list.total_pages)
                    if(response.data.list.total_pages == query.page) {
                        commit('setLastPage', true)
                    }
                    else {
                        commit('setLastPage', false)
                    }
                }

                else {
                    commit('setOwners', response.data.list ?? [])
                }
            }

            if(!response.success && response.errors) {
                for(let error in response.errors) {
                    console.log(response.errors[error])
                }
            }

            if(response.success && !response.data) {
                console.log('Se perdio la conexión con la API')
            }
        },
        viewOwner: async ({commit, rootGetters}, payload) => {
            const custom_headers = rootGetters.getHeaders;

            let query = {
                id_owner: payload.id_owner
            }

            const response = await sendRequest('catalog/owners/view', 'GET', null, null, query, custom_headers)

            if(response.success && response.data) {
                commit('setOwner', response.data.view ?? {})
            }

            if(!response.success && response.errors) {
                for(let error in response.errors) {
                    console.log(response.errors[error])
                }
            }

            if(!response.data && response.success) {
                console.log('se perdio la conexión con la API')
            }
        },
        addOwner: async ({commit, rootGetters}, payload) => {
            const custom_headers = rootGetters.getHeaders;

            let body = {
                first_name: payload.first_name,
                last_name: payload.last_name,
                second_surname: payload.second_surname,
                type: payload.type
            }

            const response = await sendRequest('catalog/owners/add', 'POST', null, body, null, custom_headers)

            if(response.success && response.data) {
                commit('setAlert', {
                    open: true,
                    message: 'Propietario añadido con éxito',
                    text_color: 'success'
                }, { root: true})
            }

            if(!response.success && response.errors) {
                for(let error in response.errors) {
                    console.log(response.errors[error])
                }

                commit('setAlert', {
                    open: true,
                    message: 'Error: la operación no se ha completado correctamente',
                    text_color: 'danger'
                }, { root: true})
            }

            if(!response.data && response.success) {
                console.log('se perdio la conexión con la API')
            }
        },
        updateOwner: async ({commit, rootGetters}, payload) => {
            const custom_headers = rootGetters.getHeaders;

            let query = {
                id_owner: payload.id_owner
            }

            let body = {
                first_name: payload.first_name,
	            last_name: payload.last_name,
	            second_surname: payload.second_surname,
	            type: payload.type
            }

            const response = await sendRequest('catalog/owners/update', 'PUT', null, body, query, custom_headers)

            if(response.success && response.data) {
                commit('setAlert', {
                    open: true,
                    message: 'Propietario actualizado con éxito',
                    text_color: 'success'
                }, { root: true})
            }

            if(!response.success && response.errors) {
                for(let error in response.errors) {
                    console.log(response.errors[error])
                }

                commit('setAlert', {
                    open: true,
                    message: 'Error: la operación no se ha completado correctamente',
                    text_color: 'danger'
                }, { root: true})
                
            }

            if(!response.data && response.success) {
                console.log('se perdio la conexión con la API')
            }
        },
        deleteOwner: async ({commit, rootGetters}, payload) => {
            const custom_headers = rootGetters.getHeaders;

            let query = {
                id_owner: payload.id_owner,
                active: payload.status
            }

            const response = await sendRequest('catalog/owners/inactive', 'DELETE', null, null, query, custom_headers)

            if(response.success && response.data) {
                commit('setAlert', {
                    open: true,
                    message: 'Propietario eliminado con éxito',
                    text_color: 'success'
                }, { root: true})
            }

            if(!response.success && response.errors) {
                for(let error in response.errors) {
                    console.log(response.errors[error])
                }

                commit('setAlert', {
                    open: true,
                    message: 'Error: la operación no se ha completado correctamente',
                    text_color: 'danger'
                }, { root: true})
            }

            if(!response.data && response.success) {
                console.log('se perdio la conexión con la API')
            }
        }
    }
}