var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"input_container"},[(_vm.label !== '')?_c('span',{class:`input_label d-flex
        ${(_vm.theme == 'dark') ? 'text-white' : 'text-dark'}`},[_c('div',{staticClass:"float-lg-left mr-3",staticStyle:{"width":"200px"}},[_vm._v(" "+_vm._s(_vm.label)+" ")]),_vm._t("label"),_vm._v(" "),_c('br')],2):_vm._e(),_c('select',_vm._g(_vm._b({class:[
            `input-thm-${_vm.theme} input-${_vm.variant}`, 
            (_vm.full) ? 'full-width' : '',
            (_vm.border_color === null) ? '': `border-${_vm.border_color}`,
            (_vm.rounded) ? 'btn-pill': ''
        ],staticStyle:{"height":"45px"},attrs:{"id":_vm._id},domProps:{"value":_vm.value}},'select',_vm.$attrs,false),_vm.listeners),[_c('option',{staticClass:"text-danger d-none",domProps:{"value":_vm.hidden_option}},[_vm._v(_vm._s(_vm.hidden_option))]),_vm._l((_vm.options),function(option,index_option){return _c('option',{key:index_option,domProps:{"value":(option.id !== undefined && option.id !== null) ? option.id : option}},[_vm._v(" "+_vm._s((option.label !== undefined && option.label !== null) ? option.label : option)+" ")])})],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }