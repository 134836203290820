<template>
    <div class="row bg-dark-gray py-2 w-100" style="border-radius: 15px;">
        <div class="col-12 col-xl-8">
            <div class="row p-0">

                <div class="col-2 p-0 d-flex flex-center py-2">
                    <img class="border" :src="picture" style="width: 100px; height: 100px; border-radius: 50%; object-fit: scale-down;"/>
                </div>

                <div class="col-5 d-flex flex-column align-items-start justify-content-center">
                    <div>
                        <span class="btn btn-outline-gray btn-pill px-4 py-0">
                            Placa
                        </span>
                        <span>
                            {{ plate }}
                        </span>
                    </div>
                    <div class="my-2">
                        <span class="btn btn-outline-gray btn-pill px-4 py-0">
                            Marca
                        </span>
                        <span>
                            {{ brand }}
                        </span>
                    </div>
                    <div>
                        <span class="btn btn-outline-gray btn-pill px-4 py-0">
                            Modelo
                        </span>
                        <span>
                            {{ model }}
                        </span>
                    </div>
                </div>

                <div class="col-5 d-flex flex-column justify-content-center">
                    <div>
                        <span class="btn btn-outline-gray btn-pill px-4 py-0">
                            Ingreso
                        </span>
                        <span>
                            {{ new Date(creation).toLocaleDateString() }}
                        </span>
                    </div>
                    <div class="mt-2">
                        <span class="btn btn-outline-gray btn-pill px-4 py-0 mr-2">
                            Año
                        </span>
                        <span>
                            {{ year}}
                        </span>
                    </div>
                </div>
            </div>
        </div>


        <div class="col-6 col-xl-2 d-flex flex-center mt-2 mt-xl-0">
            <div class="d-flex flex-center flex-column" style="border-radius: 50%; height: 100px; width: 100px;"
                :class="`bg-${color}`">
                <span>
                    {{ elapsed_days}}
                </span>
                <span style="font-size: 12px;">
                    Dias en la <br>incidencia
                </span>
            </div>
        </div>

        <div class="col-6 col-xl-2 d-flex flex-column flex-center">
            <span class="btn btn-outline-gray btn-pill px-3 py-0">
                Estimado
            </span>
            <span class="opensans-bold" style="font-size: 18px;">
                 $ {{ formatAmount(estimated_cost ?? 0, 2) }}
            </span>

            <button @click.stop="btnHandler"
                class="btn btn-primary opensans-bold btn-pill mt-2 p-0 px-3 py-1"
                style="font-size: 16px;"
                type="button">
                incidencias
            </button>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            plate: {
                type: String,
                required: false,
                default: ''
            },
            brand: {
                type: String,
                required: false,
                default: ''
            },
            model: {
                type: String,
                required: false,
                default: ''
            },
            creation: {
                type: String,
                required: false,
                default: ''
            },
            estimated_cost: {
                type: [String, Number],
                required: false, 
                default: ''
            },
            picture: {
                type: String,
                required: false,
                default: ''
            },
            elapsed_days: {
                type: Number,
                required: false,
                default: '13'
            },
            color: {
                type: String,
                required: false,
                default: 'success'
            },
            year: {
                type: String,
                required: false,
                default: ''
            }
        },
        emits: ['handler'],
        data() {
            return {}
        },
        methods: {
            btnHandler() {
                this.$emit('handler')
            }
        }
        
    }
</script>