<template>
    <li-modal :show_modal="modal_close" @onClose="$emit('close:modal')">
        <div :id="_id" class="row">
            <div class="col-12">
                <p class="manrope-title">
                    Formato para las condiciones y entrega de unidad
                </p>
                <hr>
            </div>

            <div class="col-12">
                <div class="row">
                    <div class="col-6 p-2">
                        <li-select label="Marca"
                            :options="brand_options"
                            v-model="id_brand" 
                            variant="secondary"
                            full/>
                        <span class="text-danger" v-if="errors_form.id_brand">
                            {{ errors_form.id_brand }}
                        </span>
                    </div>
                    <div class="col-6 p-2 opensans-bold">
                        <li-select label="Color:"
                            hidden_option="Seleccione..."
                            :options="colors_options"
                            v-model="id_color"
                            variant="secondary"
                            color_selector
                            full>
                        </li-select>

                        <span class="text-danger" v-if="errors_form.id_color">
                            {{ errors_form.id_color}}
                        </span>
                    </div>
                    <div class="col-6 p-2 opensans-bold">
                        <li-select label="Modelo:"
                            hidden_option="Seleccione..."
                            :options="model_options"
                            v-model="id_model"
                            variant="secondary"
                            full>
                        </li-select>
                        <span class="text-danger"  v-if="errors_form.id_model">
                            {{ errors_form.id_model}}
                        </span>
                    </div>
                    <div class="col-6">
                        <li-input label="Placas"
                            placeholder="Placas del vehiculo"
                            type="text"
                            v-model="plate"
                            full/>
                        <span class="text-danger" v-if="errors_form.plate">
                            {{ errors_form.plate }}
                        </span>
                    </div>
                    <div class="col-6">
                        <li-input label="No. serie"
                            placeholder="Numero de serie del vehiculo"
                            type="text"
                            v-model="serial_number"
                            full/>
                        <span class="text-danger" v-if="errors_form.serial_number">
                            {{ errors_form.serial_number }}
                        </span>
                    </div>
                    <div class="col-6">
                        <li-input label="Fecha de entrega"
                            type="date"
                            v-model="date"
                            full>
                        </li-input>
                        <span class="text-danger" v-if="errors_form.date">
                            {{ errors_form.date }}
                        </span>
                    </div>
                    <div class="col-6">
                        <li-input label="Kilometraje"
                            type="number"
                            placeholder="Kilometraje del vehiculo"
                            v-model="mileage"
                            full/>
                        <span class="text-danger" v-if="errors_form.mileage">
                            {{ errors_form.mileage }}
                        </span>
                    </div>
                    <div class="col-6">
                        <li-input label="TAG"
                            type="text"
                            placeholder="TAG"
                            v-model="tag"
                            full/>
                        <span class="text-danger" v-if="errors_form.tag">
                            {{ errors_form.tag }}
                        </span>
                    </div>
                    <div class="col-6">
                        <li-input label="Gasolina"
                            type="text"
                            placeholder="Gasolina"
                            v-model="fuel"
                            full/>
                        <span class="text-danger" v-if="errors_form.fuel">
                            {{ errors_form.fuel }}
                        </span>
                    </div>

                    <div class="col-12">
                        <hr class="border border-primary">
                    </div>
                </div>
            </div>

            <div class="col-12 my-2">
                <div class="row justify-content-end">
                    <div class="col-6 d-flex justify-content-end">
                        <button class="btn btn-primary btn-pill"
                            type=button>
                            Si
                        </button>
                        <button class="btn btn-danger btn-pill ml-3"
                            type="button">
                            No
                        </button>
                    </div>
                </div>
            </div>

            <div class="col-12" v-for="(group, index) in radio_groups" :key="`index-${index}`">
                <div class="row">
                    <div class="col-12 text-primary manrope-bold">
                        <p>
                            {{ group.title }}
                        </p>
                    </div>

                    <div v-for="(radio, index  ) in group.radios" :key="`index_radio-${index}`"
                        class="col-12 d-flex justify-content-between">
                        <p class="ml-4 manrope-semibold">
                            {{ radio.label }}
                        </p>
                        <label class="d-flex justify-content-between" style="width: 140px;">
                            <input v-model="radio.value" 
                                class="ml-3"
                                :value="true" 
                                type="radio" 
                                :name="radio.name" />
                            <input v-model="radio.value" 
                                class="mr-3"
                                :value="false" 
                                type="radio" 
                                :name="radio.name" />
                        </label>       
                    </div>

                    <span class="text-danger manrope-bold" v-if="errors.includes(group.title)">
                        *Informacíon incompleta
                    </span>
                </div>
            </div>

            <div class="col-12 my-2 px-4">
                <p class="ml-2 manrope-bold text-primary">
                    COMENTARIOS:
                </p>
                <textarea v-model="coments"
                    class="form-control bg-secondary border-0 px-2 text-white" rows="8">
                </textarea>
            </div>

            <div class="col-6 my-5 opensans-bold pl-4">
                <div style="width: 250px;">
                    <p>
                        Vista frontal
                    </p>
                    <li-dropfile _id="front_view" v-if="!front_view"
                        @charged="checkFile"/>

                    <div class="d-flex flex-column  py-2" v-if="front_view">
                        <img class="img-fluid"
                            :src="front_view" />
                            <button @click.stop="front_view = null"
                                class="btn btn-primary mt-2"
                                type="button">
                                Cambiar
                            </button>
                     </div>
                </div>
            </div>

            <div class="col-6 my-5 opensans-bold">
                <div style="width: 250px;">
                    <p>
                        Vista lateral (izq)
                    </p>
                    <li-dropfile _id="side_view_izq" v-if="!side_view_izq"
                        @charged="checkFile"/>

                    <div class="d-flex flex-column  py-2" v-if="side_view_izq">
                        <img class="img-fluid"
                            :src="side_view_izq" />
                            <button @click.stop="side_view_izq = null"
                                class="btn btn-primary mt-2"
                                type="button">
                                Cambiar
                            </button>
                     </div>
                </div>
            </div>

            <div class="col-6 my-5 opensans-bold pl-4">
                <div style="width: 250px;">
                    <p>
                        Vista lateral (der)
                    </p>
                    <li-dropfile _id="side_view_der" v-if="!side_view_der"
                        @charged="checkFile"/>

                    <div class="d-flex flex-column  py-2" v-if="side_view_der">
                        <img class="img-fluid"
                            :src="side_view_der" />
                            <button @click.stop="lateral_view_der = null"
                                class="btn btn-primary mt-2"
                                type="button">
                                Cambiar
                            </button>
                     </div>
                </div>
            </div>

            <div class="col-6 my-5 opensans-bold">
                <div style="width: 250px;">
                    <p>
                        Vista Posterior
                    </p>
                    <li-dropfile _id="back_view" v-if="!back_view"
                        @charged="checkFile"/>

                    <div class="d-flex flex-column  py-2" v-if="back_view">
                        <img class="img-fluid"
                            :src="back_view" />
                            <button @click.stop="back_view = null"
                                class="btn btn-primary mt-2"
                                type="button">
                                Cambiar
                            </button>
                     </div>
                </div>
            </div>

            <div class="col-6 my-5 opensans-bold pl-4">
                <div style="width: 250px;">
                    <p>
                        Herramienta de emergencia
                    </p>
                    <li-dropfile _id="tools" v-if="!tools"
                        @charged="checkFile"/>

                    <div class="d-flex flex-column  py-2" v-if="tools">
                        <img class="img-fluid"
                            :src="tools" />
                            <button @click.stop="tools = null"
                                class="btn btn-primary mt-2"
                                type="button">
                                Cambiar
                            </button>
                     </div>
                </div>
            </div>

            <div class="col-6 my-5 opensans-bold">
                <div style="width: 250px;">
                    <p>
                        Documentos
                    </p>
                    <li-dropfile _id="documents" v-if="!documents"
                        @charged="checkFile"/>

                    <div class="d-flex flex-column  py-2" v-if="documents">
                        <img class="img-fluid"
                            :src="documents" />
                            <button @click.stop="documents = null"
                                class="btn btn-primary mt-2"
                                type="button">
                                Cambiar
                            </button>
                     </div>
                </div>
            </div>

            <div class="col-12 d-flex justify-content-end py-4">
                <button @click.stop="checkForm"
                    class="btn btn-secondary btn-pill"
                    type="button">
                    Enviar
                </button>
                <button @click.stop="$emit('close:modal')"
                    class="btn btn-outline-primary btn-pill ml-3"
                    type="button">
                    Cerrar
                </button>
            </div>

        </div>
    </li-modal>
</template>

<script>
    import { mapGetters } from 'vuex';
    export default {
        name: 'li-deliver-vehicle-format',
        props: {
            _id: {
                type: String,
                required: false
            },
            modal_close: {
                type: Boolean,
                default: false
            }
        },
        emits: ['close:modal', 'send_data'],
        data(){
            return {
                radio_groups: [
                    {
                        title: 'CONDICIONES MECANICAS',
                        radios: [
                            {
                                label: 'Aceite en buenas condiciones',
                                name: 'good_oil_condition',
                                value: null
                            },
                            {
                                label: 'Sin fugas',
                                name: 'no_leaks',
                                value: null
                            },
                            {
                                label: 'Nivel de líquidos correctos',
                                name: 'correct_liquid_levels',
                                value: null
                            },
                            {
                                label: 'Frenos delanteros y traseros',
                                name: 'both_brakes',
                                value: null
                            }
                        ]
                    },
                    {
                        title: 'CONDICIONES DE SUSPENSION',
                        radios: [
                            {
                                label: 'Amortiguadores en buen estado',
                                name: 'good_condition_shokers',
                                value: null
                            },
                            {
                                label: 'Sin ruidos aparentes',
                                name: 'no_aparent_sounds',
                                value: null
                            },
                            {
                                label: 'Dirección HID en buen estado y sin fugas',
                                name: 'HID_direction',
                                value: null
                            },
                            {
                                label: 'Llanta de refacción',
                                name: 'extra_tire',
                                value: null
                            },
                            {
                                label: 'Herramienta de emergencias',
                                name: 'emergency_tools',
                                value: null
                            }
                        ]
                    },
                    {
                        title: 'CONDICIONES DE TRANSMISION',
                        radios: [
                            {
                                label: 'Caja automatica sin problemas aparentes',
                                name: 'trasmition_box',
                                value: null
                            },
                            {
                                label: 'Sin fugas',
                                name: 'leaks_transmition',
                                value: null
                            },
                            {
                                label: 'Clutch en buen estado',
                                name: 'good_clutch_condition',
                                value: null
                            }
                        ]
                    },
                    {
                        title: 'CONDICIONES ELECTRICAS',
                        radios: [
                            {
                                label: 'Todos los focos exteriores funcionan',
                                name: 'exterior_lights',
                                value: null
                            },
                            {
                                label: 'Todos los focos interiores funcionan',
                                name: 'interior_lights',
                                value: null
                            },
                            {
                                label: 'Tablero sin testigos encendidos',
                                name: 'desk_witness',
                                value: null
                            },
                            {
                                label: 'Bateria en buen estado',
                                name: 'good_battery_conditions',
                                value: null
                            },
                            {
                                label: 'GPS funcionando',
                                name: 'good_condition_GPS',
                                value: null
                            }
                        ]
                    },
                    {
                        title: 'CONDICIONES LEGALES',
                        radios: [
                            {
                                label: 'Tramites de placas realizado',
                                name: 'plates_documentation',
                                value: null
                            },
                            {
                                label: 'Tenencias al corriente',
                                name: 'interior_lights',
                                value: null
                            },
                            {
                                label: 'Verificación vigente',
                                name: 'current_verifycation',
                                value: null
                            },
                            {
                                label: 'Solo documentos necesarios en guantera',
                                name: 'only_required_documentation',
                                value: null
                            }
                        ]
                    },
                    {
                        title: 'ESTETICA EXTERIOR',
                        radios: [
                            {
                                label: 'Hojalateria y pintura en buenas condiciones',
                                name: 'good_exterior_coditions',
                                value: null
                            },
                            {
                                label: 'Limpiaparabrisas en buen estado',
                                name: 'good_condition_wiper_washer',
                                value: null
                            },
                            {
                                label: 'Llantas y birlos en buen estado',
                                name: 'good_condition_tires',
                                value: null
                            }
                        ]
                    },
                    {
                        title: 'ESTETICA INTERIOR',
                        radios: [
                            {
                                label: 'El vehiculo esta limpio',
                                name: 'clean_vehicle',
                                value: null
                            },
                            {
                                label: 'Tapetes nuevos',
                                name: 'clean_rugs',
                                value: null
                            },
                            {
                                label: 'Vestiduras en buen estado',
                                name: 'good_wearings',
                                value: null
                            },
                            {
                                label: 'Aire acondicionado funcionando',
                                name: 'good_airconditioner',
                                value: null
                            },
                            {
                                label: 'Estereo y USB funcionando',
                                name: 'stereo_usb',
                                value: null
                            },
                            {
                                label: 'El vehiculo cuenta con seguro vigente',
                                name: 'current_assurance',
                                value: null
                            },
                            {
                                label: 'El vehiculo ha sido asignado a chofre de plataforma',
                                name: 'vehicle_with_driver',
                                value: null
                            }
                        ]
                    }
                ],

                errors: [],
                errors_form: {
                    id_brand: null,
                    id_model: null,
                    id_color: null,
                    plate: null,
                    serial_number: null,
                    mileage: null,
                    date: null,
                    tag: null,
                    fuel: null    
                },
                coments: null,
                front_view: null,
                side_view_izq: null,
                side_view_der: null,
                back_view: null,
                tools: null,
                documents: null,
                files: [],
                brand_options: [],
                model_options: [],
                colors_options: [],
                id_brand: '0',
                id_model: '0',
                id_color: '0',
                plate: null,
                serial_number: null,
                mileage: null,
                date: null,
                tag: null,
                fuel: null
            }
        },
        computed: {
            ...mapGetters({
                brands: 'brands/getBrands',
                models: 'models/getModels',
                colors: 'colors/getColors',
                last_page: 'unit_administration/getLastPage',
                total_pages: 'unit_administration/getTotalPages'
            })
        },
        methods: {
            checkForm(){   
                this.errors = [];
                
                for(let error in this.errors_form) {
                    this.errors_form[error] = null
                }

                let complete = true

                if(_.isNull(this.id_brand) || _.isEmpty(this.id_brand) || this.id_brand == '0') {
                    this.errors_form.id_brand = '*Campo obligatorio'
                    complete = false
                }

                if(_.isNull(this.id_model) || _.isEmpty(this.id_model) || this.id_model == '0') {
                    this.errors_form.id_model = '*Campo obligatorio'
                    complete = false
                }

                if(_.isNull(this.id_color) || _.isEmpty(this.id_color) || this.id_color == '0') {
                    this.errors_form.id_color = '*Campo obligatorio'
                    complete = false
                }

                if(_.isNull(this.plate) || _.isEmpty(this.plate)) {
                    this.errors_form.plate = '*Campo obligatorio'
                    complete = false
                }

                if(_.isNull(this.serial_number) || _.isEmpty(this.serial_number)) {
                    this.errors_form.serial_number = '*Campo obligatorio'
                    complete = false
                }

                if(_.isNull(this.tag) || _.isEmpty(this.tag)) {
                    this.errors_form.tag = '*Campo obligatorio'
                    complete = false
                }

                if(_.isNull(this.date) || _.isEmpty(this.date)) {
                    this.errors_form.date = '*Campo obligatorio'
                    complete = false
                }

                if(_.isNull(this.fuel) || _.isEmpty(this.fuel)) {
                    this.errors_form.fuel = '*Campo obligatorio'
                    complete = false
                }

                if(_.isNull(this.mileage) || _.isEmpty(this.mileage)) {
                    this.errors_form.mileage = '*Campo obligatorio'
                    complete = false
                    return
                }

                for(let group in this.radio_groups){
                    for(let input in this.radio_groups[group].radios) {
                        
                        if(_.isNull(this.radio_groups[group].radios[input].value)) {
                            
                            if(this.errors.includes(this.radio_groups[group].title)) {
                                continue;
                            }

                            else {
                                this.errors.push(this.radio_groups[group].title)
                            }
                        }
                        else {
                            //console.log(this.radio_groups[group].radios[input].value)
                        }
                    }
                }

                if(this.errors.length == 0 && complete) {
                    let data = {
                        id_brand: this.id_brand,
                        id_color: this.id_color,
                        id_model: this.id_model,
                        plate: this.plate,
                        serial_number: this.serial_number,
                        date: this.date,
                        mileage: this.mileage,
                        fuel: this.fuel
                    }
                    this.$emit('send_data', data, this.radio_groups, this.files)
                }
            },
            checkFile(file){
                
                if(file.id === 'front_view') {
                    this.front_view = file.url;

                    let index = this.files.findIndex(file_array => file_array.id == file.id)

                    if(index == -1) {
                        this.files.push({id: file.id , file: file.file})
                    }

                    else {
                        this.files[index] = file.file
                    }
                }

                if(file.id === 'side_view_izq') {
                    this.side_view_izq = file.url;

                    let index = this.files.findIndex(file_array => file_array == file.file)

                    if(index == -1) {
                        this.files.push(file.file)
                    }

                    else {
                        this.files[index] = file.file
                    }
                }

                if(file.id === 'side_view_der') {
                    this.side_view_der = file.url;

                    let index = this.files.findIndex(file_array => file_array == file.file)

                    if(index == -1) {
                        this.files.push(file.file)
                    }

                    else {
                        this.files[index] = file.file
                    }
                }

                if(file.id === 'tools') {
                    this.tools = file.url;

                    let index = this.files.findIndex(file_array => file_array == file.file)

                    if(index == -1) {
                        this.files.push(file.file)
                    }

                    else {
                        this.files[index] = file.file
                    }
                }

                if(file.id === 'documents') {
                    this.documents = file.url;

                    let index = this.files.findIndex(file_array => file_array == file.file)

                    if(index == -1) {
                        this.files.push(file.file)
                    }

                    else {
                        this.files[index] = file.file
                    }
                }
                
                
            },
        },
        async created(){
            try {
                //opciones de modelos
                await this.$store.dispatch('models/listModels', {datatable: false, status: 'active'})
                
                this.model_options = this.models.map( model => {
                    return {
                        id: model.id_model,
                        label: model.name
                    }
                })

                this.model_options = [
                    {id: '0', label: 'Seleccione'},
                    ...this.model_options
                ]

                //opciones de marcas
                await this.$store.dispatch('brands/listBrands', {datatable: false, status: 'active'})
                this.brand_options = this.brands.map( brand => {
                    return {
                        id: brand.id_brand,
                        label: brand.name
                    }
                })

                this.brand_options = [
                    {id: 0, label: 'Seleccione'},
                    ...this.brand_options
                ]

                //opciones de colores
                await this.$store.dispatch('colors/listColors', { datatable: false, status: 'active'})
                
                this.colors_options = this.colors.map( color => {
                    return {
                        id: color.id_color,
                        label: color.name,
                        hex: color.hex
                    }
                })

                this.colors_options = [
                    {id: '0', label: 'Seleccione'},
                    ...this.colors_options
                ]
            }

            catch(error) {
                console.error(error)
            }
        }
    }
</script>

<style lang="scss" scoped>


</style>