var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row bg-dark-gray",staticStyle:{"border-radius":"15px"}},[_c('div',{staticClass:"col-4 col-xl-2 d-flex flex-column align-items-center py-3 order-1 order-xl-0"},[_c('div',{staticClass:"dot-container my-2",class:[`bg-${_vm.color}`]},[_c('span',{staticClass:"d-block dot-container__days"},[_vm._v(" "+_vm._s(_vm.elapsed_days)+" ")]),_c('span',{staticClass:"d-block dot-container__label"},[_vm._v(" Dias ")])]),_c('div',{staticClass:"text-center"},[_c('span',{staticClass:"d-block"},[_vm._v(" Fecha de inicio ")]),_c('span',{staticClass:"d-block"},[_vm._v(" "+_vm._s(new Date(_vm.init_date).toLocaleDateString())+" ")])])]),_c('div',{staticClass:"col-12 col-xl-6 d-flex flex-column justify-content-center order-4 order-xl-0"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 p-0"},[_c('span',{staticClass:"btn btn-outline-gray px-2 py-1 btn-pill mr-3",staticStyle:{"font-size":"14px"}},[_vm._v(" Descripción ")]),_c('span',[_vm._v(" "+_vm._s(_vm.description)+" ")])]),_c('div',{staticClass:"col-12 d-flex p-0 my-3 position-relative"},[_c('img',{attrs:{"src":_vm.arrow_right,"alt":""}}),_c('img',{staticStyle:{"width":"85%"},attrs:{"src":_vm.white_border,"alt":""}}),_c('img',{attrs:{"src":_vm.arrow_right,"alt":""}}),_c('img',{staticClass:"position-absolute",staticStyle:{"top":"14%"},style:(`left: ${
                    (_vm.status) == 'created' ? '5' : 
                    (_vm.status) == 'assigned' ? '14.5' :
                    (_vm.status) == 'added_part' ? '29' :
                    (_vm.status) == 'for_approval' ? '43.5' :
                    (_vm.status) == 'final_revision' ? '85' :
                    (_vm.status) == 'declined' ? '5' :
                    (_vm.status) == 'finished' ? '90' : ''
                }%;`),attrs:{"src":_vm.add_circle,"alt":""}})]),_c('div',{staticClass:"col-12 p-0"},[_c('span',{staticClass:"btn btn-outline-gray px-2 py-1 btn-pill mr-2",staticStyle:{"font-size":"14px"}},[_vm._v(" Responsable ")]),_c('span',[_vm._v(" "+_vm._s(_vm.responsible)+" ")])])])]),_c('div',{staticClass:"col-4 col-xl-2 d-flex flex-column justify-content-center order-2 order-xl-0"},[_c('div',{staticClass:"row p-0"},[_c('div',{staticClass:"col-12 text-center p-0"},[_c('span',{staticClass:"btn btn-outline-gray btn-pill d-block py-1 mb-1",staticStyle:{"font-size":"14px"}},[_vm._v(" Fecha actual ")]),_c('span',[_vm._v(" "+_vm._s(new Date().toLocaleDateString())+" ")])]),_c('div',{staticClass:"col-12 text-center p-0 mt-3"},[_c('span',{staticClass:"btn btn-outline-gray btn-pill d-block px-0 py-1 mb-2",staticStyle:{"font-size":"14px"}},[_vm._v(" Estatus ")]),_c('span',[_vm._v(" "+_vm._s(_vm.local_status[_vm.status])+" ")])])])]),_c('div',{staticClass:"col-4 col-xl-2 d-flex flex-center order-3 order-xl-0"},[_c('div',{staticClass:"row text-center mb-2"},[_vm._m(0),_c('div',{staticClass:"col-12 mt-2 opensans-bold",staticStyle:{"font-size":"20px"}},[_c('span',[_vm._v(" $ "+_vm._s(_vm.formatAmount(_vm.estimated_cost ?? 0))+" ")])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-12"},[_c('p',{staticClass:"m-0"},[_vm._v(" Costo estimado ")])])
}]

export { render, staticRenderFns }