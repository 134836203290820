import sendRequest from '../../../libs/sendRequest'

export const insurances = {
    namespaced: true,
    state: {
        insurances:[],
        insurance: [],
        last_page: false,
        total_pages: null
    },
    getters: {
        getInsurances: (state) => (state.insurances),
        getInsurance: (state) => (state.insurance),
        getLastPage: (state) => (state.last_page),
        getTotalPages: (state) => (state.total_pages)
    },
    mutations: {
        setInsurances: (state, payload) => state.insurances = payload,
        setInsurance: (state, payload) => state.insurance = payload,
        setLastPage: (state, payload) => state.last_page = payload,
        setTotalPages: (state, payload) => state.total_pages = payload
    },
    actions: {
        listInsurances: async ({ commit, rootGetters}, payload) => {
            const custom_headers = rootGetters.getHeaders;

            let query = {
                datatable: payload.datatable,
                page: payload.page,
                rows: payload.rows,
                search: '',
                order_by: 'remaining_days',
                order_asc: payload.order_asc,
                status: payload.status
            }

            if(payload.search) {
                query.search = payload.search
            }

            const response =  await sendRequest('rental/vehicles/insurances/list', 'GET', null, null, query, custom_headers);

            if(response.success && response.data) {
                if(query.datatable) {
                    commit('setInsurances', response.data.list.result ?? [])
                    commit('setTotalPages', response.data.list.total_pages)
                    if(response.data.list.total_pages == query.page) {
                        commit('setLastPage', true)
                    }
                    else {
                        commit('setLastPage', false)
                    }
                }

                else {
                    commit('setInsurances', response.data.list ?? [])
                }
            }

            if(!response.success && response.errors) {
                for(let error in response.errors) {
                    console.log(response.errors[error])
                }
            }

            if(response.success && !response.data) {
                console.log('Se perdio la conexión con la API')
            }
        },
        addInsurance: async ({ commit, rootGetters}, payload) => {

            const custom_headers = rootGetters.getHeaders;
    
            const body = {
                id_vehicle: payload.id_vehicle,
                policy_number: payload.policy_number,
                premium_value: payload.premium_value,
                id_insurance_company: payload.id_insurance_company,
                periodicity: payload.periodicity,
                date_init: payload.date_init,
                date_end: payload.date_end,
                picture: payload.picture,
                insured_amount: payload.insured_amount,
                beneficiarys_name: payload.beneficiarys_name
            }
    
            const response = await sendRequest('rental/vehicles/insurances/add', 'POST', null, body, null, custom_headers);
    
            if(response.success && response.data) {
                commit('setAlert', {
                    open: true,
                    message: 'Seguro añadido con éxito',
                    text_color: 'success'
                  }, { root: true})
            }
    
            if(!response.success && response.errors) {
                for(let error in response.errors) {
                    console.log(response.errors[error])
                }
    
                commit('setAlert', {
                    open: true,
                    message: 'Un error ha ocurrido intente más tarde',
                    text_color: 'danger'
                  }, { root: true})
            }
    
            if(response.success && !response.data) {
                console.log('Se perdio la conexión con la API')
            }
        }
    },
}