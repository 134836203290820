<template>
    <aside id="sidebar" :class="`sidebar bg-${bg_color} py-4 d-flex flex-column justify-content-between`">
        <section class="sidebar__logo d-flex justify-content-center">
            <img :src="img_logo" class="">
        </section>

        <!-- Inicio -->
        <section class="sidebar__content d-flex flex-wrap justify-content-center align-items-start manrope-semibold" style="max-height: 70vh; overflow-y: scroll;">

            <div @click="redirect('dashboard')" v-if="getSession?.id_rol === 1"
                :class="['text-crop py-2 px-3 my-2 d-flex flex-column align-items-center']">
                    <div class="circle mb-2 sidebar__content--item"
                        :class="[(current_route_name === 'dashboard') ? 'sidebar__content--item-active' : '']">
                        <img :src="pc_icon" class="">
                    </div>
                    <span>Inicio</span>
            </div>

            <!-- Unidades-->
            <div @click="redirect('units')" 
                    :class="['text-crop py-2 px-3 my-2 d-flex flex-column align-items-center']">
                    <div class="circle mb-2 sidebar__content--item"
                        :class="[(current_route_name === 'units') ? 'sidebar__content--item-active' : '']">
                        <img :src="car_icon" class="">
                    </div>    
                    <span>Unidades</span>
            </div>

            <!-- Conductores -->
            <div @click="redirect('drivers')" v-if="getSession?.id_rol === 1"
                :class="['text-crop py-2 px-3 my-2 d-flex flex-column align-items-center']">
                    <div class="circle mb-2 sidebar__content--item"
                        :class="[(current_route_name === 'drivers') ? 'sidebar__content--item-active' : '']">
                        <img :src="driver_icon" class="">
                    </div>    
                    <span>Choferes</span>
            </div>

            <!-- Rentas -->
            <div @click="redirect('rentals')" v-if="getSession?.id_rol === 1"
                :class="['text-crop py-2 px-3 my-2 d-flex flex-column align-items-center']">
                    <div class="circle mb-2 sidebar__content--item"
                        :class="[(current_route_name === 'rentals') ? 'sidebar__content--item-active' : '']">
                        <img :src="check_icon" class="">
                    </div>    
                    <span>Rentas</span>
            </div>

            <!-- Contabilidad -->
            <div @click="redirect('accounting')" v-if="getSession?.id_rol === 1"
                :class="['text-crop py-2 px-3 my-2 d-flex flex-column align-items-center']">
                    <div class="circle mb-2 sidebar__content--item"
                        :class="[(current_route_name === 'accounting') ? 'sidebar__content--item-active' : '']">
                        <img :src="calculator_icon" class="">
                    </div>    
                    <span>Contabilidad</span>
            </div>

            <!-- Contabilidad -->
            <div @click="redirect('page-prices')" v-if="getSession?.id_rol === 1"
                :class="['text-crop py-2 px-3 my-2 d-flex flex-column align-items-center']">
                    <div class="circle mb-2 sidebar__content--item"
                        :class="[(current_route_name === 'page-prices') ? 'sidebar__content--item-active' : '']">
                        <img :src="calculator_icon" class="">
                    </div>    
                    <span>Live One</span>
            </div>

            <!--  incidencias -->
            <div @click="redirect('incidents')" 
                :class="['text-crop py-2 px-3 my-2 d-flex flex-column align-items-center']">
                    <div class="circle mb-2 sidebar__content--item"
                        :class="[(current_route_name === 'incidents') ? 'sidebar__content--item-active' : '']">
                        <img :src="heart_icon" class="">
                    </div>    
                    <span>Incidencias</span>
            </div>

            <!--  Inventarios -->
            <div @click="redirect('inventory')" 
                :class="['text-crop py-2 px-3 my-2 d-flex flex-column align-items-center']">
                    <div class="circle mb-2 sidebar__content--item"
                        :class="[(current_route_name === 'inventory') ? 'sidebar__content--item-active' : '']">
                        <img :src="warehouse_icon" class="">
                    </div>    
                    <span>Inventario</span>
            </div>

            <!-- Catalogos -->
            <div @click="redirect('catalogues')" v-if="getSession?.id_rol === 1"
                :class="['text-crop py-2 px-3 my-2 d-flex flex-column align-items-center']">
                    <div class="circle mb-2 sidebar__content--item"
                        :class="[(current_route_name === 'catalogues') ? 'sidebar__content--item-active' : '']">
                        <img :src="book_icon" class="" width="25">
                    </div>    
                    <span>Catalogos</span>
            </div>

            

            

        </section>

        <section>
            <!-- Cerrar sesión -->
            <div @click="logout()" 
                :class="['text-crop py-2 px-3  d-flex flex-column align-items-center align-self-end']">
                    <img :src="logout_icon" class="float-left pr-2 mb-2">
                    <span>Cerrar Sesión</span>
            </div>
        </section>

    </aside>
</template>

<script>
    import { mapGetters } from 'vuex'
    
    // IMAGES
    import img_logo from 'ASSETS/images/sidebar/icons/logo.png'
    import pc_icon from 'ASSETS/images/sidebar/icons/pc_icon.png'
    import car_icon from 'ASSETS/images/sidebar/icons/car_icon.png'
    import driver_icon from 'ASSETS/images/sidebar/icons/driver_icon.png'
    import check_icon from 'ASSETS/images/sidebar/icons/check-square.svg'
    import calculator_icon from 'ASSETS/images/sidebar/icons/calculator_icon.png'
    import heart_icon from 'ASSETS/images/sidebar/icons/heart_icon.png'
    import book_icon from 'ASSETS/images/sidebar/icons/book_icon.png'
    import logout_icon from 'ASSETS/images/sidebar/icons/logout.png'
    import warehouse_icon from 'ASSETS/images/sidebar/icons/warehouse.png'

    export default {
        name: 'gl-sidebar',
        props: {
            bg_color: {
                type: String,
                required: false,
                default: 'sidebar',
                validator: function (value) {
                    let allow_colors = CONFIG.class_colors.concat(['sidebar'])
                    return allow_colors.includes(value)
                }
            }
        },
        data() {
            return {
                //icons
                img_logo,
                pc_icon,
                car_icon,
                driver_icon,
                check_icon,
                calculator_icon,
                heart_icon,
                book_icon,
                logout_icon,
                warehouse_icon
            }
        },
        computed: {
            ...mapGetters([
                'getSession'
            ]),

            current_env() {
                return CONFIG.env
            },
            current_route_name() {
                return this.$route.name
            },
            data_session() {
                return this.getSession
            },
        },
        methods: {
            redirect(route_name) {
                if(this.current_route_name !== route_name) {
                    window.scrollTo(0, 0)

                    this.$router.push({
                        name: route_name
                    })
                }
            },
            logout() {
                this.$store.commit('deleteSession')
                this.redirect('login')
            }

        },
        mounted() {
            
        }
    }
</script>

<style lang="scss" scoped>

    .sidebar {
        position: fixed;
        top: 0;
        left: 0;
        width:140px;
        height: 100vh;
        overflow-y: auto;
        overflow-x: hidden;
        z-index: 5;

        &__logo {
            
        }

        &__content {
            padding: 20px 15px;

            &--item {
                width: 50px !important;
            }

            &--subitem {
                width: 156px !important;
            }

            &--item, &--subitem {
                cursor: pointer;
                border-radius: 12px;
                color: white !important;
                
                -webkit-transition: background-color .8s !important;
                -moz-transition: background-color .8s !important;
                -ms-transition: background-color .8s !important;
                -o-transition: background-color .8s !important;
                transition: background-color .8s !important;
            }

            &--item-active, &--subitem-active {
                background-color: rgb(0,140,156);

            }

            div &--item:last-child {
                position: absolute;
                bottom: 20px;
            }

            &--drop {
                height: 0;
                opacity: 0;
                clear: both;
                overflow: hidden;
            }
            &--drop-show {
                opacity: 1;
                height: auto;
            }

        }

        .circle{
            height: 50px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
        }
    }

    .bg-sidebar {
        background-color: rgba(0, 0, 0, 0.3);
    }
</style>
