import Vue from 'vue'
import Vuex from 'vuex'
import sendRequest from '../libs/sendRequest'

import { brands }  from './modules/catalogues/brands.js'
import { models } from './modules/catalogues/models.js'
import { vehicle_types } from './modules/catalogues/vehicleTypes.js'
import { spare_parts } from './modules/catalogues/spareParts'
import { drivers } from './modules/drivers/drivers.js'
import { rentals  } from './modules/drivers/rentals'
import { unit_administration } from './modules/units/unitAdministration.js'
import { insurances } from './modules/units/insurance'
import { users } from './modules/core/users.js'
import { user_roles } from './modules/core/userRoles.js'
import { colors } from './modules/catalogues/colors'
import { insurance_companies } from './modules/catalogues/insuranceCompanies'
import { maintenances } from './modules/units/maintenances'
import { verifications } from './modules/units/verifications'
import { incidents } from './modules/drivers/incidents'
import { page_prices } from './modules/catalogues/pagePrices'
import { cashbox  } from './modules/cashbox/cashbox.js'
import { owners } from './modules/catalogues/owners.js'
import { regions } from './modules/catalogues/regions.js'
import { document_types } from './modules/catalogues/documentTypes.js'
import { inventory } from './modules/inventory/inventory.js'
import { dashboard } from './modules/dashboard/dashboard.js'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    session: null,
	  alert: {}
  },
  mutations: {
    setSession(state, payload) {
      state.session = payload
      sessionStorage.setItem('live@session', JSON.stringify(payload))
    },
    setAlert(state, payload) {
      state.alert = payload
    },
    deleteSession(state, payload) {
      sessionStorage.removeItem('live@session')
      state.session = null
    }
  },
  actions: {
    async login({ commit}, payload) {

      const body = {
        username: payload.username,
        password: payload.password
      }
      const response = await sendRequest('auth/login', 'POST', null, body, null, null)
      

      if(response.success && response.data) {
        //@TODO notification success
        
        commit('setSession', response.data)
      }

      if(!response.success && response.errors) {
        for(let error in response.errors) {
          console.log(response.errors[error])
        }
        commit('setAlert', {
          open: true,
          message: 'Nombre de usuario o contraseña incorrectos',
		    text_color: 'danger'
        })
      }

      if(response.success && !response.data) {
        console.log('Se ha perdido la conexión con la API')
      }

    }
  },
  getters: {
    getHeaders (state) {
      return {
        ...CONFIG.server[CONFIG.env].headers,
        session: JSON.parse(sessionStorage.getItem('live@session')).token_session
      }
    },
    getLogin(state) {
      return state.login
    },
    getSession(state) {
      state.session = JSON.parse(sessionStorage.getItem('live@session'))
      return state.session
    },
    getAlert(state) {
      return state.alert
    }
  },
  modules: {
    brands, models, vehicle_types, drivers, unit_administration, users, user_roles, spare_parts, colors,
    rentals, insurances, insurance_companies, maintenances, verifications, incidents, page_prices, cashbox, owners, regions, document_types, inventory, dashboard
  }
})
