import sendRequest from '../../../libs/sendRequest'

export const user_roles = {
    namespaced: true,
    state: {
        user_role: [],
        user_roles: [],
        last_page: false,
        total_pages: null
    },
    getters: {
        getUserRoles: (state) => (state.user_roles),
        getUserRole: (state) => (state.user_role),
        getLastPage: (state) => (state.last_page),
        getTotalPages: (state) => (state.total_pages)
    },
    mutations: {
        setUserRole: (state, payload) => state.user_role = payload,
        setUserRoles: (state, payload) => state.user_roles = payload,
        setLastPage: (state, payload) => state.last_page = payload,
        setTotalPages: (state, payload) => state.total_pages = payload
    },
    actions: {
        listUserRoles: async ({ commit, rootGetters}, payload) => {
            const custom_headers = rootGetters.getHeaders;

            let query = {
                datatable: payload.datatable,
                page: payload.page,
                rows: payload.rows,
                search: '',
                order_by: 'id_rol',
                order_asc: payload.order_asc,
                status: payload.status
            }

            if(payload.search) {
                query.search = payload.search
            }

            const response =  await sendRequest('core/roles/list', 'GET', null, null, query, custom_headers);
            console.log('response roles:', response)
            if(response.success && response.data) {
                if(query.datatable) {
                    commit('setUserRoles', response.data.list.result ?? [])
                    commit('setTotalPages', response.data.list.total_pages)
                    if(response.data.list.total_pages == query.page) {
                        commit('setLastPage', true)
                    }
                    else {
                        commit('setLastPage', false)
                    }
                }

                else {
                    commit('setUserRoles', response.data.list ?? [])
                }
                
            }

            if(!response.success && response.errors) {
                for(let error in response.errors) {
                    console.log(response.errors[error])
                }
            }

            if(response.success && !response.data) {
                console.log('Se perdio la conexión con la API')
            }
        },
        viewUserRole: async ({commit, rootGetters}, payload) => {
            const custom_headers = rootGetters.getHeaders;

            let query = {
                id_rol: payload.id_rol
            }

            const response = await sendRequest('core/roles/view', 'GET', null, null, query, custom_headers)

            if(response.success && response.data) {
                commit('setUserRole', response.data.view)
            }

            if(!response.success && response.errors) {
                for(let error in response.errors) {
                    console.log(response.errors[error])
                }
            }

            if(!response.data && response.success) {
                console.log('se perdio la conexión con la API')
            }
        },
        addUserRole: async ({commit, rootGetters}, payload) => {
            const custom_headers = rootGetters.getHeaders;

            let body = {
                name: payload.name
            }

            const response = await sendRequest('core/roles/add', 'POST', null, body, null, custom_headers)

            if(response.success && response.data) {
                commit('setAlert', {
                    open: true,
                    message: 'Rol de usuario añadido con éxito',
                    text_color: 'success'
                  }, { root: true})
            }

            if(!response.success && response.errors) {
                for(let error in response.errors) {
                    console.log(response.errors[error])
                }

                commit('setAlert', {
                    open: true,
                    message: 'Error: la operación no se ha completado correctamente',
                    text_color: 'danger'
                  }, { root: true})
            }

            if(!response.data && response.success) {
                console.log('se perdio la conexión con la API')
            }
        },
        updateUserRole: async ({commit, rootGetters}, payload) => {
            const custom_headers = rootGetters.getHeaders;

            let query = {
                id_rol: payload.id_rol
            }

            let body = {
                name: payload.name
            }

            const response = await sendRequest('core/roles/update', 'PUT', null, body, query, custom_headers)

            if(response.success && response.data) {
                commit('setAlert', {
                    open: true,
                    message: 'Rol de usuario actualizado con éxito',
                    text_color: 'success'
                }, { root: true})
            }

            if(!response.success && response.errors) {
                for(let error in response.errors) {
                    console.log(response.errors[error])
                }
                commit('setAlert', {
                    open: true,
                    message: 'Error: la operación no se ha completado correctamente',
                    text_color: 'danger'
                }, { root: true})
            }

            if(!response.data && response.success) {
                console.log('se perdio la conexión con la API')
            }
        },
        deleteUserRole: async ({commit, rootGetters}, payload) => {
            const custom_headers = rootGetters.getHeaders;

            let query = {
                id_rol: payload.id_rol
            }

            const response = await sendRequest('core/roles/inactive', 'DELETE', null, null, query, custom_headers)

            if(response.success && response.data) {
                commit('setAlert', {
                    open: true,
                    message: 'Rol de usuario eliminado con éxito',
                    text_color: 'success'
                }, { root: true})
            }

            if(!response.success && response.errors) {
                for(let error in response.errors) {
                    console.log(response.errors[error])
                }

                commit('setAlert', {
                    open: true,
                    message: 'Error: la operación no se ha completado correctamente',
                    text_color: 'danger'
                }, { root: true})
            }

            if(!response.data && response.success) {
                console.log('se perdio la conexión con la API')
            }
        }
    }
}