import sendRequest from '../../../libs/sendRequest'

export const cashbox = {
    namespaced: true,
    state: {
        cashbox: [],
        cashboxes: [],
        available_years: [],
        available_weeks: [],
        last_page: false,
        total_pages: null,
        payments: [],
        resume: {}
    },
    getters: {
        getCashboxes: (state) => (state.cashboxes),
        getCashbox: (state) => (state.cashbox),
        getAvailableYears: (state) => (state.available_years),
        getAvailableWeeks: (state) => (state.available_weeks),
        getLastPage: (state) => (state.last_page),
        getTotalPages: (state) => (state.total_pages),
        getPayments: (state) => (state.payments),
        getResume: (state) => (state.resume)
    },
    mutations: {
        setCashboxes: (state, payload) => state.cashboxes = payload,
        setCashbox: (state, payload) => state.cashbox = payload,
        setLastPage: (state, payload) => state.last_page = payload,
        setTotalPages: (state, payload) => state.total_pages = payload,
        setPayments: (state, payload) => state.payments = payload,
        setAvailableYears: (state,payload) => state.available_years = payload,
        setAvailableWeeks: (state,payload) => state.available_weeks = payload,
        setResume: (state, payload) => state.resume = payload
    },
    actions: {
        listCashboxes: async({commit, rootGetters}, payload) => {
            const custom_headers = rootGetters.getHeaders;

            let query = {
                datatable: payload.datatable,
                page: payload.page,
                rows: payload.rows,
                search: '',
                order_by: 'id_cashbox',
                order_asc: payload.order_asc,
                status: payload.status,
                id_vehicle: payload.id_vehicle,
                number_week: payload.number_week,
                year: payload.year
            }

            if(payload.search) {
                query.search = payload.search
            }

            const response =  await sendRequest('cashbox/cashboxs/list', 'GET', null, null, query, custom_headers);
         
            if(response.success && response.data) {

                if(query.datatable) {
                    commit('setCashboxes', response.data.list.result ?? [])
                    commit('setResume', response.data.resume ?? {})
                    commit('setTotalPages', response.data.list.total_pages)
                    if(response.data.list.total_pages == query.page) {
                        commit('setLastPage', true)
                    }
                    else {
                        commit('setLastPage', false)
                    }
                }

                else {
                    commit('setCashboxes', response.data.list)
                    commit('setResume', response.data.resume ?? {})
                }
                
            }

            if(!response.success && response.errors) {
                for(let error in response.errors) {
                    console.log(response.errors[error])
                }
            }

            if(response.success && !response.data) {
                console.log('Se perdio la conexión con la API')
            }
        },
        listPayments: async({ commit, rootGetters}, id) =>  {
            const custom_headers = rootGetters.getHeaders;

            const response = await sendRequest('cashbox/cashboxs/view', 'GET', null, null, { id_cashbox: id}, custom_headers);

            if(response.success && response.data) {
                commit('setPayments', response.data.view.payments ?? [])
            }
        },
        viewCashbox: async({ commit, rootGetters}, cashbox_id) => {
            const custom_headers = rootGetters.getHeaders;

            const response = await sendRequest('cashbox/cashboxs/view', 'GET', null, null, cashbox_id, custom_headers);

            if(response.success && response.data) {
                commit('setCashbox', response.data.view);
            }

            else if(!response.success && response.errors) {
                console.log(response.errors);
            }

            else if(response.success && !response.data) {
                console.log('Se perdió la conexión con la API')
            }
        },
        addBalance: async({commit, rootGetters}, data) => {
            const custom_headers = rootGetters.getHeaders;

            let body = data.body;
            let query = data.query;

            const response = await sendRequest('cashbox/cashboxs/add_balance', 'PUT', null, body, query, custom_headers);

            if(response.success && response.data) {
                //todo success notificaction
                commit('setAlert', {
                    open: true,
                    message: 'Información añadida con éxito',
                    text_color: 'success'
                  }, { root: true})
            }

            else if(!response.success && response.errors) {
                for(let error in response.errors) {
                    if(response.errors[error].code = 'error_cashbox_status_incorrect') {
                        commit('setAlert', {
                            open: true,
                            message: 'Solo se permiten pagos en una caja del tipo "Balance" o "Pago Parcial"',
                            text_color: 'danger'
                          }, { root: true})
                    }

                    else if(response.errors[error].code = 'error_cash_card_does_match_earnings') {
                        commit('setAlert', {
                            open: true,
                            message: 'Las suma de las cantidades (Tarjeta o Efectivo) no coinciden con lo registrado como ganancias totales.',
                            text_color: 'danger'
                          }, { root: true})
                    }
                    else {
                        commit('setAlert', {
                            open: true,
                            message: 'Un error ha ocurrido itente más tarde',
                            text_color: 'danger'
                          }, { root: true})
                    }

                }
            }

            else if(response.success && !response.data) {
                
                console.log('Se ha perdido la conexión con la API');
            }


        },
        addPayment: async({ commit, rootGetters}, data) => {
            const custom_headers = rootGetters.getHeaders;

            let body = data.body;
            let query = data.query;

            const response = await sendRequest('cashbox/cashboxs/add_pay', 'PUT', null, body, query, custom_headers);

            if(response.success && response.data) {
                //todo success notificaction
                commit('setAlert', {
                    open: true,
                    message: 'Pago añadido con éxito',
                    text_color: 'success'
                  }, { root: true})
            }

            else if(!response.success && response.errors) {
                for(let error in response.errors) {
                    if(response.errors[error].code = 'error_cashbox_status_incorrect') {
                        commit('setAlert', {
                            open: true,
                            message: `Error en el tipo de pago pago correcto: ${response.errors[error].detail.correct_type == 'driver_to_gl' ? 'Conductor a Gl' : 'Gl a conductor'}`,
                            text_color: 'danger'
                          }, { root: true})
                    }

                    else if(response.errors[error].code = 'error_type_incorrect') {
                        commit('setAlert', {
                            open: true,
                            message: 'El tipo de pago es incorrecto.',
                            text_color: 'danger'
                          }, { root: true})
                    }

                    else if(response.errors[error].code = 'error_quantity_is_higher_than_expected') {
                        commit('setAlert', {
                            open: true,
                            message: 'La cantidad añadida supera el balance pendiente.',
                            text_color: 'danger'
                          }, { root: true})
                    }

                    else {
                        commit('setAlert', {
                            open: true,
                            message: 'Un error ha ocurrido itente más tarde',
                            text_color: 'danger'
                          }, { root: true})
                    }
                }
            }

            else if(response.success && !response.data) {
                
                console.log('Se ha perdido la conexión con la API');
            }
        },
        availableYears: async({commit, rootGetters}) => {
            const custom_headers = rootGetters.getHeaders;

            const response =  await sendRequest('cashbox/cashboxs/list', 'GET', null, null, { datatable: false}, custom_headers);

            if(response.success && response.data) {
                let data = response.data.list ?? [];

                let options = []

                for(let i in data) {
                    let index = options.findIndex((option) => option.id == new Date(data[i].creation).toISOString().slice(0,4))

                    if(index < 0) {
                        options.push({id: new Date(data[i].creation).toISOString().slice(0,4), label: `${new Date(data[i].creation).toISOString().slice(0,4)}` })
                    }
                }

                options = [{id: 'seleccione', label: 'Seleccione'}, ...options]

                commit('setAvailableYears', options)
            }
        },
        availableWeeks: async({commit, rootGetters}, year) => {
            const custom_headers = rootGetters.getHeaders;

            const response =  await sendRequest('cashbox/cashboxs/get_weeks_year', 'GET', null, null, { year: year}, custom_headers);

            if(response.success && response.data) {
                let data = response.data.view.weeks ?? [];

                let options = data.map((week) => {
                    return {
                        id: week.week_number,
                        label: `Semana del ${new Date(week.first_day).toLocaleDateString() } al ${new Date(week.last_day).toLocaleDateString()}`
                    }
                })

                

                console.log(options)

                commit('setAvailableWeeks', options)
            }
        }
    }
}