import sendRequest from '../../../libs/sendRequest'

export const verifications = {
    namespaced: true,
    state: {
        verifications:[],
        expired_verifications: [],
        verification: [],
        last_page: false,
        total_pages: null
    },
    getters: {
        getVerifications: (state) => (state.verifications),
        getExpiredVerifications: (state) => (state.expired_verifications),
        getVerification: (state) => (state.verification),
        getLastPage: (state) => (state.last_page),
        getTotalPages: (state) => (state.total_pages)
    },
    mutations: {
        setVerifications: (state, payload) => state.verifications = payload,
        setExpiredVerifications: (state, payload) => state.verifications = payload,
        setVerification: (state, payload) => state.verification = payload,
        setLastPage: (state, payload) => state.last_page = payload,
        setTotalPages: (state, payload) => state.total_pages = payload
    },
    actions: {
        listVerifications: async ({ commit, rootGetters}, payload) => {
            const custom_headers = rootGetters.getHeaders;

            let query = {
                datatable: payload.datatable,
                page: payload.page,
                rows: payload.rows,
                search: '',
                order_by: 'remaining_days',
                order_asc: payload.order_asc,
                status: payload.status
            }

            if(payload.search) {
                query.search = payload.search
            }

            const response =  await sendRequest('rental/vehicles/verifications/list', 'GET', null, null, query, custom_headers);
            
            if(response.success && response.data) {
                if(query.datatable) {
                    commit('setVerifications', response.data.list.result ?? [])
                    commit('setTotalPages', response.data.list.total_pages)
                    if(response.data.list.total_pages == query.page) {
                        commit('setLastPage', true)
                    }
                    else {
                        commit('setLastPage', false)
                    }
                }

                else {
                    commit('setVerifications', response.data.list ?? [])
                }
            }

            if(!response.success && response.errors) {
                for(let error in response.errors) {
                    console.log(response.errors[error])
                }
            }

            if(response.success && !response.data) {
                console.log('Se perdio la conexión con la API')
            }
        },
        listExpiredVerifications: async ({ commit, rootGetters}, payload) => {
            const custom_headers = rootGetters.getHeaders;

            let query = {
                datatable: payload.datatable,
                page: payload.page,
                rows: payload.rows,
                search: '',
                order_by: 'remaining_days',
                order_asc: payload.order_asc,
                status: payload.status
            }

            if(payload.search) {
                query.search = payload.search
            }

            const response =  await sendRequest('', 'GET', null, null, query, custom_headers);
            
            if(response.success && response.data) {
                if(query.datatable) {
                    commit('setExpiredVerifications', response.data.list.result)
                    commit('setTotalPages', response.data.list.total_pages)
                    if(response.data.list.total_pages == query.page) {
                        commit('setLastPage', true)
                    }
                    else {
                        commit('setLastPage', false)
                    }
                }

                else {
                    commit('setExpiredVerifications', response.data.list)
                }
            }

            if(!response.success && response.errors) {
                for(let error in response.errors) {
                    console.log(response.errors[error])
                }
            }

            if(response.success && !response.data) {
                console.log('Se perdio la conexión con la API')
            }
        },
        addVerification: async ({ commit, rootGetters}, payload) => {

            const custom_headers = rootGetters.getHeaders;
    
            const body = {
                id_vehicle: payload.id_vehicle,
                date: payload.date
            }
    
            const response = await sendRequest('rental/vehicles/verifications/add', 'POST', null, body, null, custom_headers);
    
            if(response.success && response.data) {
                commit('setAlert', {
                    open: true,
                    message: 'Verificación añadida con éxito',
                    text_color: 'success'
                  }, { root: true})
            }
    
            if(!response.success && response.errors) {
                for(let error in response.errors) {
                    console.log(response.errors[error])
                }
                
            }
    
            if(response.success && !response.data) {
                console.log('Se perdio la conexión con la API')
            }
        },
        expiredVerification: async ({ commit, rootGetters}, payload) => {

            const custom_headers = rootGetters.getHeaders;
    
            const body = {
                date: payload.date
            }

            const query = {
                id_verification: payload.id_verification
            }
    
            const response = await sendRequest('', 'PUT', null, body, query, custom_headers);
    
            if(response.success && response.data) {
                commit('setAlert', {
                    open: true,
                    message: 'Verificación añadida con éxito',
                    text_color: 'success'
                  }, { root: true})
            }
    
            if(!response.success && response.errors) {
                for(let error in response.errors) {
                    console.log(response.errors[error])
                }
                
            }
    
            if(response.success && !response.data) {
                console.log('Se perdio la conexión con la API')
            }
        },
        
    },
}