<template>
    <div id="template-dashboard" class="p-0 m-0 container-fluid">
    	<sidebar bg_color="secondary-dark"></sidebar>

		<section class="row w-100 bg-gray align-items-center justify-content-between" 
			style="min-height: 80px; padding-left: 140px;">

            <div class="col-4 col-md-5 col-lg-2 order-lg-1 p-0">
                <div class="row p-0 m-0">

                    <div class="col-3 d-flex justify-content-center align-items-center p-0 m-0">
                        <img :src="avatar_img"/>
                    </div>

                    <div class="col col-md-9 col-lg-8 pl-2 d-flex flex-column justify-content-center align-items-start">
                        <p class="m-0">
                            {{ session?.username }}
                        </p>

                        <span class="m-0" style="font-size: 12px;">
                            Bienvenido!
                        </span>
                    </div>
                </div>
            </div>

        </section>

    	<section class="section_main">
    		<slot></slot>	
    	</section>

    	<section class="bg-color bg-dark">
    		
    	</section>
        <li-alert />
	</div>
</template>

<script>
	import Sidebar from '../components/Sidebar.vue'

	//images 
    import avatar_img from 'ASSETS/images/avatar.png'
    import user_icon from 'ASSETS/images/sidebar/icons/user_icon.png'
    import bell_icon from 'ASSETS/images/sidebar/icons/bell_icon.png'

    //helpers
    import { mapGetters } from 'vuex'

	export default {
		name: 'template-dashboard',
		components: {
            'sidebar': Sidebar,
        },
		data() {
			return {
				avatar_img,
                user_icon,
                bell_icon,
			}
		},
        computed:{
            ...mapGetters({
                session: 'getSession',
            })
        },
        methods: {
            redirect(route_name) {
                if(this.current_route_name !== route_name) {
                    window.scrollTo(0, 0)
                    this.$router.push({
                        name: route_name
                    })
                }
            },
        }
	}
</script>


<style lang="scss" scoped>
    #template-dashboard {
        //padding: 0px;

        .section_main {
        	min-height: 100vh;
	        padding: 0px 0px 0px 140px;
	        overflow-y: hidden !important;
        }

        .bg-color {
        	z-index: -1;
        	top: 0px;
        	left: 0px;
        	position: fixed;
        	height: 100vh;
        	width: 100vw;
        }
    }
</style>