<template>
    <section class="square-box">
        <div :id="_id" 
            class="drop-area" 
            :class="[`border-${variant}`]"
            @dragover="dragOver($event)" 
            @dragleave="dragLeave($event)" 
            @drop="drop($event)"
            v-if="!uploaded">

                <span class="drop_title">
                    Arrastra y suelta tu archivo
                </span>
                <span class="">o</span>

                <label :for="`input-file-${_id}`" :class="`btn btn-${variant}`">
                    <span class="text-white">
                        Selecciona tu archivo
                    </span>

                    <input :id="`input-file-${_id}`" 
                        @change="changeFile($event)"
                        type="file"
                        hidden>
                </label>
        </div>
        <div class="drop-download cursor-pointer" 
            :class="[`border-${variant}`]"
            @click="downloadTigger()"
            v-if="uploaded">

            <span class="drop_title">
                <img :src="ico_download">
                Descargar
            </span>
        </div>

        <div class="drop-preview" v-if="!uploaded">
        </div>
    </section>

</template>

<script>
    export default {
        data() {
            return {
                drop_area: {},
                drop_title: {},
                file: {}
            }
        },
        props: {
            _id: {
                required: true,
                type: String
            },
            variant: {
                required: false,
                type: String,
                default: 'primary',
                validator: function (value) {
                    // El valor debe coincidir con una de estas cadenas de texto
                    return ['primary', 'secondary', 'success', 'warning', 'danger', 'light'].includes(value)
                }
            },
            uploaded: {
                required: false,
                type: Boolean,
                default: false
            },
            downloaded: { 
                required: false,
                type: Boolean,
                default: false
            }
        },
        emit: ['charged', 'download'],
        methods: {
            dragOver(event) {
                event.preventDefault()
                this.drop_area.classList.add('active')
                this.drop_title.textContent = 'Suelta para subir archivo'
            },
            dragLeave(event){
                event.preventDefault()
                this.drop_area.classList.remove('active')
                this.drop_title.textContent = 'Arrastra y suelta tu archivo'
            },
            drop(event) {
                event.preventDefault()
                this.drop_area.classList.remove('active')

                const fr = new FileReader();
                let image = event.dataTransfer.files[0]
                fr.readAsDataURL(image)
                fr.addEventListener('loaded',() => {
                    this.$emit('charged', { id: this._id, file: event.dataTransfer.files[0], url: fr.result })
                    this.drop_title.textContent = 'Arrastra y suelta tu archivo'
                }) 
            },
            changeFile(event) {
                this.drop_area.classList.add('active')
                let file = this.drop_area.querySelector(`#input-file-${this._id}`)

                const fr = new FileReader();
                let image = event.target.files[0]
                 fr.readAsDataURL(image)

                 fr.addEventListener('loadend', () => {
                    this.$emit('charged', { id: this._id, file: file.files[0], url: fr.result })
                    this.drop_area.classList.remove('active')
                 })

            },
            downloadTrigger(){
                emit('download', this._id)
            }
        },
        mounted() {
            if(!this.downloaded) {
                this.drop_area = document.querySelector(`#${this._id}`)
                this.drop_title = document.querySelector('.drop_title') 
            }
        }
    }

</script>

<style lang="scss" scoped>
    .square-box {
        position: relative;
        width: 100%;
        overflow: hidden;

        .drop-area, .drop-download {
            border-radius: 5px;
            padding: 10px;

            // square
            position:  absolute;
            top: 0; left: 0; bottom: 0; right: 0;

            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
        }

        .drop-area.active, .drop-download:hover {
            background-color: #B8D4FE;
            color: black;
            border: 2px dashed #618AC9;
        }
    }
    .square-box:before {
        content: "";
        display: block;
        padding-top: 100%;
    }

    .drop-preview {
        margin-top: 10px;
    }
</style>