module.exports = {
	env: "dev",
	server: {
		local: {
			page_url: 'http://localhost:8080',
			api: 'http://localhost:3004',
			headers: {
				'Accept': 'application/json, text/plain, /',
            	'Content-Type': 'application/json',
				"reference": "com.grupolive.system",
				'Authorization': 'Bearer ba+BldFH/xgFGmIV+Z4+Ry94zz1VM8S0lafeRLdxZZbOY09LMxXu8hDccKae4AFpfELN42hrK/oc+nnt/iutIQ=='
			}
		},
		dev: {
			page_url: 'https://admin-gl.monocilindrero.co',
			api: 'https://api-gl.monocilindrero.co',
			headers: {
				'Accept': 'application/json, text/plain, /',
            	'Content-Type': 'application/json',
				"reference": "com.grupolive.system",
				'Authorization': 'Bearer ba+BldFH/xgFGmIV+Z4+Ry94zz1VM8S0lafeRLdxZZYmGygLiNMmwMc+9yxDQrQ+X0gbDg5ke0ZCc6up5W5Q6g=='
			}
		},
		prod: {
			page_url: 'https://gladmin.livetransitmexico.com/',
			api: 'https://api-gl.monocilindrero.co',
			headers: {
				'Accept': 'application/json, text/plain, /',
            	'Content-Type': 'application/json',
				'Authorization': 'Bearer '
			}
		}
	},
	class_colors: ['primary',	'primary-light', 'primary-dark', 'secondary', 'secondary-light', 'secondary-dark', 
		'success', 'success-light', 'success-dark', 'warning', 'warning-light', 'warning-dark', 'danger', 
		'danger-light', 'danger-dark', 'info', 'info-light', 'info-dark', 'light', 'light-light', 'light-dark', 
		'dark', 'dark-light', 'dark-dark'],
}