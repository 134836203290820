<template>
    <div class="row bg-dark-gray" style="border-radius: 15px;">
        <div class="col-4 col-xl-2 d-flex flex-column align-items-center py-3 order-1 order-xl-0">

            <div class="dot-container my-2"
                :class="[`bg-${color}`]">
                <span class="d-block dot-container__days">
                    {{ elapsed_days}}
                </span>
                <span class="d-block dot-container__label">
                    Dias
                </span>
            </div>

            <div class="text-center">
                <span class="d-block">
                    Fecha de inicio
                </span>
                <span class="d-block">
                    {{ new Date(init_date).toLocaleDateString() }}
                </span>
            </div>
        </div>

        <div class="col-12 col-xl-6 d-flex flex-column justify-content-center order-4 order-xl-0">
            <div class="row">
                <div class="col-12 p-0">
                    <span class="btn btn-outline-gray px-2 py-1 btn-pill mr-3"
                        style="font-size: 14px;">
                        Descripción
                    </span>
                    <span>
                        {{ description }}
                    </span>
                </div>
                <div class="col-12 d-flex p-0 my-3 position-relative">
                    <img :src="arrow_right" alt="">
                    <img style="width: 85%;" :src="white_border" alt="">
                    <img :src="arrow_right" alt="">
                    <img class="position-absolute" style="top: 14%;" :style="`left: ${
                        (status) == 'created' ? '5' : 
                        (status) == 'assigned' ? '14.5' :
                        (status) == 'added_part' ? '29' :
                        (status) == 'for_approval' ? '43.5' :
                        (status) == 'final_revision' ? '85' :
                        (status) == 'declined' ? '5' :
                        (status) == 'finished' ? '90' : ''
                    }%;`" :src="add_circle" alt="">
                </div>
                <div class="col-12 p-0">
                     <span class="btn btn-outline-gray px-2 py-1 btn-pill mr-2"
                        style="font-size: 14px;">
                        Responsable
                    </span>
                    <span>
                        {{ responsible }}
                    </span>
                </div>
            </div>
        </div>

        <div class="col-4 col-xl-2 d-flex flex-column justify-content-center order-2 order-xl-0">
            <div class="row p-0">
                <div class="col-12 text-center p-0">
                    <span class="btn btn-outline-gray btn-pill d-block py-1 mb-1"
                        style="font-size: 14px;">
                        Fecha actual
                    </span>
                    <span>
                        {{ new Date().toLocaleDateString() }}
                    </span>
                </div>
                <div class="col-12 text-center p-0 mt-3">
                    <span class="btn btn-outline-gray btn-pill d-block px-0 py-1 mb-2"
                        style="font-size: 14px;">
                        Estatus
                    </span>
                    <span>
                        {{ local_status[status] }}
                    </span>
                </div>
            </div>
        </div>

        <div class="col-4 col-xl-2 d-flex flex-center order-3 order-xl-0">
            <div class="row text-center mb-2">
                <div class="col-12">
                    <p class="m-0">
                        Costo estimado
                    </p>
                </div>
                <div class="col-12 mt-2 opensans-bold"
                    style="font-size: 20px;">
                    <span>
                        $ {{ formatAmount(estimated_cost ?? 0) }}
                    </span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import arrow_right from 'ASSETS/icons/arrow_right.svg'
    import white_border from 'ASSETS/icons/row.svg'
    import add_circle from 'ASSETS/icons/add_circle.svg'

    export default {
        data() {
            return {
                arrow_right,
                white_border,
                add_circle,
                local_status: {
                    'created': 'Creado',
                    'assigned': 'Asignado',
                    'added_part': 'Refacción añadida',
                    'for_approval':'En revisión',
                    'final_revision': 'Aprobación final',
                    'declined': 'Declinada',
                    'finished': 'Finalizada'
                }
            }
        },
        props: {
            elapsed_days: {
                type: Number,
                required: false,
                default: 5
            },
            init_date: {
                type: String,
                required: false,
                default: '18/03/2024'
            },
            description: {
                type: String,
                required: false,
                default: 'Descripción por defecto'
            },
            responsible: {
                type: String,
                required: false,
                default: 'Responsable'
            },
            status: {
                type: String,
                required: false,
                default: ''
            },
            estimated_cost: {
                type: [String, Number],
                required: false,
                default: '1,200.0'
            },
            color: {
                type: String,
                required: false,
                default: 'success'
            }
        }
    }
</script>


<style lang="scss" scoped>
    
    .dot-container {
        width: 100px;
        height: 100px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
    }
</style>