import sendRequest from '../../../libs/sendRequest'

export const incidents = {
    namespaced: true,
    state: {
        incidents: [],
        incident: [],
        incident_types: [],
        availiable_parts: [],
        used_parts: [],
        last_page: false,
        total_pages: null
    },
    getters: {
        getIncidents: (state) => (state.incidents),
        getIncident: (state) => (state.incident),
        getLastPage: (state) => (state.last_page),
        getTotalPages: (state) => (state.total_pages),
        getIncidentsTypes: (state) => (state.incident_types),
        getAvailableParts: (state) => (state.availiable_parts),
        getUsedParts: (state) => (state.used_parts)
    },
    mutations: {
        setIncidents: (state, payload) => state.incidents = payload, 
        setIncident: (state, payload) => state.incident = payload, 
        setLastPage: (state, payload) => state.last_page = payload,
        setTotalPages: (state, payload) => state.total_pages = payload,
        setIncidentsTypes: (state, payload) => state.incident_types = payload,
        setAvailableParts: (state, payload) => state.availiable_parts = payload,
        setUsedParts: (state, payload) => state.used_parts = payload
    },
    actions: {
        listIncidents: async ({ commit, rootGetters}, payload) => {
            const custom_headers = rootGetters.getHeaders;

            let query = {
                datatable: payload.datatable,
                page: payload.page,
                rows: payload.rows,
                search: '',
                order_by: 'id_incident',
                order_asc: payload.order_asc,
                status: payload.status,
                all: payload.all
            }

            if(payload.search) {
                query.search = payload.search
            }

            if(payload.id_vehicle) {
                query.id_vehicle = payload.id_vehicle
            }

            const response =  await sendRequest('rental/incidents/list', 'GET', null, null, query, custom_headers);
            
            if(response.success && response.data) {

                if(query.datatable) {
                    commit('setIncidents', response.data.list.result ?? [])
                    commit('setTotalPages', response.data.list.total_pages)
                    if(response.data.list.total_pages == query.page) {
                        commit('setLastPage', true)
                    }
                    else {
                        commit('setLastPage', false)
                    }
                }

                else {
                    commit('setRentals', response.data.list ?? [])
                }
            }

            if(!response.success && response.errors) {
                for(let error in response.errors) {
                    console.log(response.errors[error])
                }
            }

            if(response.success && !response.data) {
                console.log('Se perdio la conexión con la API')
            }
        },
        listIncidentsTypes: async ({ commit, rootGetters}) => {
            const custom_headers = rootGetters.getHeaders;

            let query = {
                datatable: false,
                order_by: 'id_incident_type',
                order_asc: false,
                status: 'active'
            }

            const response = await sendRequest('catalog/incident_types/list', 'GET', null, null, query, custom_headers);

            if(response.success && response.data) {

                if(response.data.hasOwnProperty('list') && response.data.list.length > 0) {
                    let options = response.data.list.map((type) => {
                        return {
                            id: type.id_incident_type,
                            label: type.name
                        }
                    })

                    options = [
                        {id: 0, label: 'Seleccionar'},
                        ...options
                    ]

                    commit('setIncidentsTypes', options)
                }

                else {
                    commit('setIncidentsTypes', []);
                }

            }
        },
        viewIncident: async ({ commit, rootGetters}, payload) => {
            const custom_headers = rootGetters.getHeaders;

            let query = {
                id_incident: payload.id_incident
            }


            const response =  await sendRequest('rental/incidents/view', 'GET', null, null, query, custom_headers);
            console.log('response:', response)
            if(response.success && response.data) {
                commit('setIncident', response.data.view)
                
            }

            if(!response.success && response.errors) {
                for(let error in response.errors) {
                    console.log(response.errors[error])
                }
            }

            if(response.success && !response.data) {
                console.log('Se perdio la conexión con la API')
            }
        },
        addIncident: async ({ commit, rootGetters}, payload) => {
            const custom_headers = rootGetters.getHeaders;

            const body = {
                id_vehicle: payload.id_vehicle,
                id_incident_type: payload.id_incident_type,
                description: payload.description,
                date_incident: payload.date_incident
            }

            const response =  await sendRequest('rental/incidents/add', 'POST', null, body, null, custom_headers);
            
            if(response.success && response.data) {
                commit('setAlert', {
                    open: true,
                    message: 'Incidencia creada con éxito',
                    text_color: 'success'
                  }, { root: true})
            }

            if(!response.success && response.errors) {
                for(let error in response.errors) {
                    console.log(response.errors[error])
                }

                commit('setAlert', {
                    open: true,
                    message: 'Un error ha ocurrido intente más tarde.',
                    text_color: 'danger'
                  }, { root: true})
            }

            if(response.success && !response.data) {
                console.log('Se perdio la conexión con la API')
            }
        },
        assignResponsible: async ({ commit, rootGetters}, payload) => {
            const custom_headers = rootGetters.getHeaders;

            const body = {
                id_responsible: payload.id_responsible,
                estimated_price: payload.estimated_price,
                estimated_date: payload.estimated_date
            }

            const query = {
                id_incident: payload.id_incident
            }

            const response =  await sendRequest('rental/incidents/assign_responsible', 'PUT', null, body, query, custom_headers);
            
            if(response.success && response.data) {
                commit('setAlert', {
                    open: true,
                    message: 'Incidencia asignada con éxito',
                    text_color: 'success'
                  }, { root: true})
            }

            if(!response.success && response.errors) {
                for(let error in response.errors) {
                    console.log(response.errors[error])
                }

                commit('setAlert', {
                    open: true,
                    message: 'Un error ha ocurrido intente más tarde.',
                    text_color: 'danger'
                  }, { root: true})
            }

            if(response.success && !response.data) {
                console.log('Se perdio la conexión con la API')
            }
        },
        requestApproval: async ({ commit, rootGetters}, payload) => {
            const custom_headers = rootGetters.getHeaders;

            const query = {
                id_incident: payload.id_incident
            }

            const response =  await sendRequest('rental/incidents/request_approval', 'PUT', null, null, query, custom_headers);
            
            if(response.success && response.data) {
                commit('setAlert', {
                    open: true,
                    message: 'Solicitud enviada con éxito',
                    text_color: 'success'
                  }, { root: true})
            }

            if(!response.success && response.errors) {
                for(let error in response.errors) {
                    if(response.errors[error].code == "error_this_isnt_your_incident") {
                        commit('setAlert', {
                            open: true,
                            message: 'No se permite solicitar revisión a incidencias que no te ha sido asignadas.',
                            text_color: 'Info'
                          }, { root: true})
                    }

                    else {
                        commit('setAlert', {
                            open: true,
                            message: 'Ha ocurrido un error, intente más tarde.',
                            text_color: 'danger'
                          }, { root: true})
                    }
                }

                
            }

            if(response.success && !response.data) {
                console.log('Se perdio la conexión con la API')
            }
        },
        approveIncident: async ({ commit, rootGetters}, payload) => {
            const custom_headers = rootGetters.getHeaders;

            const query = {
                id_incident: payload.id_incident
            }

            const response =  await sendRequest('rental/incidents/approve', 'PUT', null, null, query, custom_headers);
            
            if(response.success && response.data) {
                commit('setAlert', {
                    open: true,
                    message: 'Solicitud enviada con éxito',
                    text_color: 'success'
                  }, { root: true})
            }

            if(!response.success && response.errors) {
                for(let error in response.errors) {
                    console.log(response.errors[error])
                }

                commit('setAlert', {
                    open: true,
                    message: 'Un error ha ocurrido intente más tarde.',
                    text_color: 'danger'
                  }, { root: true})
            }

            if(response.success && !response.data) {
                console.log('Se perdio la conexión con la API')
            }
        },
        finishIncident: async ({ commit, rootGetters}, payload) => {
            const custom_headers = rootGetters.getHeaders;

            const query = {
                id_incident: payload.id_incident
            }

            const body = {
                damages_cost: payload.damages_cost
            }

            const response =  await sendRequest('rental/incidents/finish', 'PUT', null, body, query, custom_headers);
            
            if(response.success && response.data) {
                commit('setAlert', {
                    open: true,
                    message: 'Incidencia cerrada con éxito',
                    text_color: 'success'
                  }, { root: true})
            }

            if(!response.success && response.errors) {
                for(let error in response.errors) {

                    if(response.errors[error].code == 'error_cant_finish_step_isnt_final_revision') {
                        commit('setAlert', {
                            open: true,
                            message: 'Solo se pueden cerrar incidencias con estatus de "Revisión final""',
                            text_color: 'warning'
                          }, { root: true})
                    }

                    else {

                        commit('setAlert', {
                            open: true,
                            message: 'Un error ha ocurrido intente más tarde.',
                            text_color: 'danger'
                          }, { root: true})
                    }
                    console.log(response.errors[error])
                }
            }

            if(response.success && !response.data) {
                console.log('Se perdio la conexión con la API')
            }
        },
        declineIncident: async ({ commit, rootGetters}, payload) => {
            const custom_headers = rootGetters.getHeaders;

            const query = {
                id_incident: payload.id_incident
            }

            const body = {
                limit_date: payload.limit_date,
                reason: payload.reason,
                picture: payload.picture,
                parts_declined: payload.parts_declined
            }

            const response =  await sendRequest('rental/incidents/decline', 'PUT', null, body, query, custom_headers);
            
            if(response.success && response.data) {
                commit('setAlert', {
                    open: true,
                    message: 'Incidencia declinada con éxito',
                    text_color: 'success'
                  }, { root: true})
            }

            if(!response.success && response.errors) {
                for(let error in response.errors) {
                    console.log(response.errors[error])
                }

                commit('setAlert', {
                    open: true,
                    message: 'Un error ha ocurrido intente más tarde.',
                    text_color: 'danger'
                  }, { root: true})
            }

            if(response.success && !response.data) {
                console.log('Se perdio la conexión con la API')
            }
        },
        listAvaliableParts: async({ commit, rootGetters}, query) => {
            const custom_headers = rootGetters.getHeaders;

            const response =  await sendRequest('rental/incidents/see_available_parts', 'GET', null, null, query, custom_headers);
            
            if(response.success && response.data) {
                let options = response.data.view.map((part) => {

                    return {
                        id: part.id_part_inventory,
                        label: `número de serie: ${part.serial_number_originating} año: ${part.year_originating} condición: ${part.part_condition == 'new' ? 'nuevo' : part.part_condition == 'used' ? 'segunda mano' : part.part_condition == 'repaired' ? 'reparada' :''}`
                    }
                })

                options = [{id: 0, label: 'Seleccione'}, ...options]

                commit('setAvailableParts',options)
            }

            if(!response.success && response.errors) {
                for(let error in response.errors) {
                    console.log(response.errors[error])
                }

            }

            if(response.success && !response.data) {
                console.log('Se perdio la conexión con la API')
            }
        },
        addPart: async({commit, rootGetters}, query ) => {
            const custom_headers = rootGetters.getHeaders;

            const response =  await sendRequest('rental/incidents/add_part', 'PUT', null,{ id_part_inventory: query.id_part_inventory},{id_incident: query.id_incident}, custom_headers);
            
            if(response.success && response.data) {
                commit('setAlert', {
                    open: true,
                    message: 'Parte añadida con éxito',
                    text_color: 'success'
                  }, { root: true})
            }

            if(!response.success && response.errors) {
                for(let error in response.errors) {
                    console.log(response.errors[error])
                }

                commit('setAlert', {
                    open: true,
                    message: 'Un error ha ocurrido intente más tarde.',
                    text_color: 'danger'
                  }, { root: true})
            }

            if(response.success && !response.data) {
                console.log('Se perdio la conexión con la API')
            }
        },
        listUsedParts: async({commit, rootGetters}, query) => {
            const custom_headers = rootGetters.getHeaders;

            const response =  await sendRequest('rental/incidents/view', 'GET', null, null, query, custom_headers);
            console.log('response:', response)
            if(response.success && response.data) {

                let options = []

                response.data.view.steps.forEach( step => {
                    if(step.incident_status == 'added_part' && step.active )  {
                        options.push({
                            id_incident_step: step.id_incident_step,
                            name: `refacción id: ${step.id_part_inventory}`,
                            accepted: true,
                            reason: '',
                            error: ''
                        })
                    }
                });

                commit('setUsedParts', options)
 
            }

            if(!response.success && response.errors) {
                for(let error in response.errors) {
                    console.log(response.errors[error])
                }
            }

            if(response.success && !response.data) {
                console.log('Se perdio la conexión con la API')
            }
        }

    }
}