import sendRequest from '../../../libs/sendRequest'

export const maintenances = {
    namespaced: true,
    state: {
        maintenances:[],
        maintenance: [],
        last_page: false,
        total_pages: null
    },
    getters: {
        getMaintenances: (state) => (state.maintenances),
        getMaintenance: (state) => (state.maintenance),
        getLastPage: (state) => (state.last_page),
        getTotalPages: (state) => (state.total_pages)
    },
    mutations: {
        setMaintenances: (state, payload) => state.maintenances = payload,
        setMaintenance: (state, payload) => state.maintenance = payload,
        setLastPage: (state, payload) => state.last_page = payload,
        setTotalPages: (state, payload) => state.total_pages = payload
    },
    actions: {
        listMaintenances: async ({ commit, rootGetters}, payload) => {
            const custom_headers = rootGetters.getHeaders;

            let query = {
                datatable: payload.datatable,
                page: payload.page,
                rows: payload.rows,
                search: '',
                order_by: 'mileage_upcoming_maintenances',
                order_asc: payload.order_asc,
                status: payload.status
            }

            if(payload.search) {
                query.search = payload.search
            }

            const response =  await sendRequest('rental/vehicles/mileage_maintenance/list', 'GET', null, null, query, custom_headers);

            if(response.success && response.data) {
                if(query.datatable) {
                    commit('setMaintenances', response.data.list.result ?? [])
                    commit('setTotalPages', response.data.list.total_pages)
                    if(response.data.list.total_pages == query.page) {
                        commit('setLastPage', true)
                    }
                    else {
                        commit('setLastPage', false)
                    }
                }

                else {
                    commit('setMaintenances', response.data.list ?? [])
                }
            }

            if(!response.success && response.errors) {
                for(let error in response.errors) {
                    console.log(response.errors[error])
                }
            }

            if(response.success && !response.data) {
                console.log('Se perdio la conexión con la API')
            }
        },
        addMileage: async ({ commit, rootGetters}, payload) => {

            const custom_headers = rootGetters.getHeaders;
    
            const body = {
                id_vehicle: payload.id_vehicle,
                current_mileage: payload.current_mileage
            }
    
            const response = await sendRequest('rental/vehicles/mileage/add', 'POST', null, body, null, custom_headers);
    
            if(response.success && response.data) {
                commit('setAlert', {
                    open: true,
                    message: 'Kilometraje añadido con éxito',
                    text_color: 'success'
                  }, { root: true})
            }
    
            if(!response.success && response.errors) {
                for(let error in response.errors) {
                    if(response.errors[error].code == "error_mileage_should_be_may_last_mileage" ) {
                        commit('setAlert', {
                            open: true,
                            message: 'EL kilometraje no puede ser menor al anterior',
                            text_color: 'danger'
                          }, { root: true})
                    }

                    else {
                        commit('setAlert', {
                            open: true,
                            message: 'Ha ocurrido un error intente más tarde',
                            text_color: 'danger'
                          }, { root: true})
                    }
                }
                
                console.log(response.errors)
            }
    
            if(response.success && !response.data) {
                console.log('Se perdio la conexión con la API')
            }
        },
        addMaintenance: async ({ commit, rootGetters}, payload) => {

            const custom_headers = rootGetters.getHeaders;
    
            const body = {
                id_vehicle: payload.id_vehicle,
                current_mileage: payload.current_mileage
            }
    
            const response = await sendRequest('rental/vehicles/mileage_maintenance/add', 'POST', null, body, null, custom_headers);
    
            if(response.success && response.data) {
                commit('setAlert', {
                    open: true,
                    message: 'Mantenimiento añadido con éxito',
                    text_color: 'success'
                  }, { root: true})
            }
    
            if(!response.success && response.errors) {
                for(let error in response.errors) {
                    
                    if(response.errors[error].code == "error_mileage_maintenance_should_be_may_last_mileage" ) {
                        commit('setAlert', {
                            open: true,
                            message: 'EL kilometraje no puede ser menor al anterior',
                            text_color: 'danger'
                          }, { root: true})
                    }

                    else {
                        commit('setAlert', {
                            open: true,
                            message: 'Ha ocurrido un error intente más tarde',
                            text_color: 'danger'
                          }, { root: true})
                    }
                    
                }
    
                
            }
    
            if(response.success && !response.data) {
                console.log('Se perdio la conexión con la API')
            }
        },
    },
}