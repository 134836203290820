import sendRequest from '../../../libs/sendRequest'

export const document_types = {
    namespaced: true,
    state: {
        document_type: [],
        document_types: [],
        last_page: false,
        total_pages: null
    },
    getters: {
        getDocumentTypes: (state) => (state.document_types),
        getDocumentType: (state) => (state.document_type),
        getLastPage: (state) => (state.last_page),
        getTotalPages: (state) => (state.total_pages)
    },
    mutations: {
        setDocumentType: (state, payload) => state.document_type = payload,
        setDocumentTypes: (state, payload) => state.document_types = payload,
        setLastPage: (state, payload) => state.last_page = payload,
        setTotalPages: (state, payload) => state.total_pages = payload
    },
    actions: {
        list: async ({ commit, rootGetters}, payload) => {
            const custom_headers = rootGetters.getHeaders;

            let query = {
                datatable: payload.datatable,
                page: payload.page,
                rows: payload.rows,
                search: '',
                order_by: 'id_driver_document_type',
                order_asc: payload.order_asc,
                status: payload.status
            }

            if(payload.search) {
                query.search = payload.search
            }

            const response =  await sendRequest('catalog/driver_document_types/list', 'GET', null, null, query, custom_headers);
            
            if(response.success && response.data) {
                
                if(query.datatable) {
                    commit('setDocumentTypes', response.data.list.result ?? [])
                    commit('setTotalPages', response.data.list.total_pages)
                    if(response.data.list.total_pages == query.page) {
                        commit('setLastPage', true)
                    }
                    else {
                        commit('setLastPage', false)
                    }
                }

                else {
                    commit('setDocumentTypes', response.data.list)
                }
            }

            if(!response.success && response.errors) {
                for(let error in response.errors) {
                    console.log(response.errors[error])
                }
            }

            if(response.success && !response.data) {
                console.log('Se perdio la conexión con la API')
            }
        },
        view: async ({commit, rootGetters}, query) => {
            const custom_headers = rootGetters.getHeaders;

            const response = await sendRequest('catalog/driver_document_types/view', 'GET', null, null, query, custom_headers)

            if(response.success && response.data) {
                commit('setDocumentType', response.data.view ?? {})
            }

            if(!response.success && response.errors) {
                for(let error in response.errors) {
                    console.log(response.errors[error])
                }
            }

            if(!response.data && response.success) {
                console.log('se perdio la conexión con la API')
            }
        },
        add: async ({commit, rootGetters}, body) => {
            const custom_headers = rootGetters.getHeaders;

            const response = await sendRequest('catalog/driver_document_types/add', 'POST', null, body, null, custom_headers)

            if(response.success && response.data) {
                commit('setAlert', {
                    open: true,
                    message: 'Documento añadido con éxito',
                    text_color: 'success'
                  }, { root: true})
            }

            if(!response.success && response.errors) {
                for(let error in response.errors) {
                    console.log(response.errors[error])
                }

                commit('setAlert', {
                    open: true,
                    message: 'Error: la operación no se ha completado correctamente',
                    text_color: 'danger'
                  }, { root: true})
            }

            if(!response.data && response.success) {
                console.log('se perdio la conexión con la API')
            }
        },
        update: async ({commit, rootGetters}, payload) => {
            const custom_headers = rootGetters.getHeaders;

            const response = await sendRequest('catalog/driver_document_types/update', 'PUT', null, payload.body, payload.query, custom_headers)

            if(response.success && response.data) {
                commit('setAlert', {
                    open: true,
                    message: 'Documento actualizado con éxito',
                    text_color: 'success'
                }, { root: true})
            }

            if(!response.success && response.errors) {
                for(let error in response.errors) {
                    console.log(response.errors[error])
                }
                commit('setAlert', {
                    open: true,
                    message: 'Error: la operación no se ha completado correctamente',
                    text_color: 'danger'
                }, { root: true})
            }

            if(!response.data && response.success) {
                console.log('se perdio la conexión con la API')
            }
        },
        delete: async ({commit, rootGetters}, id_driver_document_type) => {
            const custom_headers = rootGetters.getHeaders;

            const response = await sendRequest('catalog/driver_document_types/inactive', 'DELETE', null, null, id_driver_document_type, custom_headers)

            if(response.success && response.data) {
                commit('setAlert', {
                    open: true,
                    message: 'Documento eliminado con éxito',
                    text_color: 'success'
                }, { root: true})
            }

            if(!response.success && response.errors) {
                for(let error in response.errors) {
                    console.log(response.errors[error])
                }

                commit('setAlert', {
                    open: true,
                    message: 'Error: la operación no se ha completado correctamente',
                    text_color: 'danger'
                }, { root: true})
            }

            if(!response.data && response.success) {
                console.log('se perdio la conexión con la API')
            }
        }
    }
}